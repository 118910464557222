import React, { useEffect } from "react";
import "./BluContentSection.css"; // Archivo CSS adecuado
const bluStoryImage =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FbluStory.png?alt=media&token=db764857-2891-4327-bfc3-07d4ae621d05"; // Imagen Blu Story
const bioPageWavesImage =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2FbioPageWaves.svg?alt=media&token=7740b2a0-b011-4ce8-9040-c1c9a74c1f05"; // Imagen bioPageWaves
const carousel1 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel1.jpg?alt=media&token=91d97773-5908-4ff7-be3f-e858e496e685"; // Imagen carrusel 1
const carousel2 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel2.jpg?alt=media&token=c400da4a-a94c-4856-9054-67cc6e9e817b"; // Imagen carrusel 2
const carousel3 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel3.jpg?alt=media&token=6a743bc7-8495-4d10-ba00-12b7168cb891"; // Imagen carrusel 3
const carousel4 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel4.jpg?alt=media&token=a126dbed-283c-4e8e-a217-4ce431f710ff"; // Imagen carrusel 4
const carousel5 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BioPage%2FImages%2Fcarousel5.jpg?alt=media&token=8cf40640-f91a-437a-8f02-448035d27f64"; // Imagen carrusel 5

const BluContentSection = () => {
  useEffect(() => {
    const bluStoryButton = document.getElementById("bluStory");
    const givingBackButton = document.getElementById("givingBack");
    const bluStorySection = document.querySelector(".bluStory");
    const bluWorldSection = document.querySelector(".bluWorld");
    const bioPageWaves = document.querySelector(".bioPageWaves");
    const targetHeight = 1130; // Altura objetivo de .bluStory en px

    const handleBluStoryClick = () => {
      if (!bluWorldSection.classList.contains("hidden")) {
        bluWorldSection.style.transition =
          "height 0.5s ease-in-out, opacity 0.5s ease-in-out";
        bluWorldSection.style.height = "0px";
        bluWorldSection.style.opacity = "0";
        setTimeout(() => {
          bluWorldSection.classList.add("hidden");
          bluWorldSection.style.height = "";
          bluWorldSection.style.display = "none";
        }, 500);
        givingBackButton.classList.remove("bluStoryActive");
      }

      if (bluStorySection.classList.contains("hidden")) {
        bluStorySection.classList.remove("hidden");
        bluStorySection.style.height = "0px";
        bluStorySection.style.overflow = "hidden";
        bluStorySection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        bioPageWaves.style.transition = "opacity 0.6s ease-in-out";

        setTimeout(() => {
          bluStorySection.style.height = `${targetHeight}px`;
          bioPageWaves.style.opacity = "0";
        }, 10);

        bluStoryButton.classList.add("bluStoryActive");
      } else {
        bioPageWaves.style.opacity = "1";
        bluStorySection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        bluStorySection.style.height = "0px";
        setTimeout(() => {
          bluStorySection.classList.add("hidden");
        }, 600);
        bluStoryButton.classList.remove("bluStoryActive");
      }
    };

    const handleGivingBackClick = () => {
      if (!bluStorySection.classList.contains("hidden")) {
        bluStorySection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        bluStorySection.style.height = "0px";
        setTimeout(() => {
          bluStorySection.classList.add("hidden");
          bioPageWaves.style.transition = "opacity 0.6s ease-in-out";
          bioPageWaves.style.opacity = "1";
        }, 600);
        bluStoryButton.classList.remove("bluStoryActive");
      }

      if (bluWorldSection.classList.contains("hidden")) {
        bluWorldSection.classList.remove("hidden");
        bluWorldSection.style.display = "block";
        bluWorldSection.style.height = "0px";
        bluWorldSection.style.opacity = "0";
        bluWorldSection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";

        setTimeout(() => {
          const height = bluWorldSection.scrollHeight + "px";
          bluWorldSection.style.height = height;
          bluWorldSection.style.opacity = "1";
        }, 10);

        givingBackButton.classList.add("bluStoryActive");
      } else {
        bluWorldSection.style.transition =
          "height 0.6s ease-in-out, opacity 0.6s ease-in-out";
        bluWorldSection.style.height = "0px";
        bluWorldSection.style.opacity = "0";
        setTimeout(() => {
          bluWorldSection.classList.add("hidden");
          bluWorldSection.style.height = "";
          bluWorldSection.style.display = "none";
        }, 600);
        givingBackButton.classList.remove("bluStoryActive");
      }
    };

    const handleMouseEnter = (event) => {
      event.target.style.transform = "scale(1.2)";
    };

    const handleMouseLeave = (event) => {
      event.target.style.transform = "scale(1)";
    };

    const cards = document.querySelectorAll(".carousel li");

    cards.forEach((card) => {
      card.addEventListener("mouseenter", handleMouseEnter);
      card.addEventListener("mouseleave", handleMouseLeave);
    });

    bluStoryButton.addEventListener("click", handleBluStoryClick);
    givingBackButton.addEventListener("click", handleGivingBackClick);

    return () => {
      bluStoryButton.removeEventListener("click", handleBluStoryClick);
      givingBackButton.removeEventListener("click", handleGivingBackClick);

      cards.forEach((card) => {
        card.removeEventListener("mouseenter", handleMouseEnter);
        card.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  return (
    <section>
      <div className="buyerBrokerAgreement__bottomButtons">
        <button id="givingBack">Giving Back</button>
        <button id="bluStory">Blu Story</button>
      </div>
      <div className="bluStory hidden">
        <h2 className="bluStory__title">Blu Story</h2>
        <img
          src={bluStoryImage}
          alt="Blu Story"
          className="bluStoryImg"
          draggable="false"
        />
      </div>
      <div className="bluWorld hidden">
        <div className="bluWorld__logo"></div>
        <h2 className="bluWorld__title">Building a Better World</h2>
        <p className="bluWorld__description">
          <button className="bluWorld__link" onClick={() => {}}>
            Blu World
          </button>
          , Blu Realty's non-profit foundation, focuses on ocean clean-up,
          sustainability, and restoration of nature's habitats.
        </p>
        <ul className="carousel">
          <li
            className="card1"
            style={{ backgroundImage: `url(${carousel1})` }}
          ></li>
          <li
            className="card2"
            style={{ backgroundImage: `url(${carousel2})` }}
          ></li>
          <li
            className="card3"
            style={{ backgroundImage: `url(${carousel3})` }}
          ></li>
          <li
            className="card4"
            style={{ backgroundImage: `url(${carousel4})` }}
          ></li>
          <li
            className="card5"
            style={{ backgroundImage: `url(${carousel5})` }}
          ></li>
        </ul>
        <h3 className="bluWorld__subtitle">Shaping Tomorrow</h3>
      </div>
      <img
        src={bioPageWavesImage}
        className="bioPageWaves"
        alt="Ondas de la página de biografía"
        draggable="false"
      />
    </section>
  );
};

export default BluContentSection;
