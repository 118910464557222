import { useEffect, useState } from "react";

export default function WelcomeSection() {
  const [landLordName, setLandLordName] = useState("");

  useEffect(() => {
    // Función para obtener los parámetros después del hash
    function getHashParameters() {
      var hash = window.location.hash.substring(1);
      var params = new URLSearchParams(hash.split("?")[1]); // Divide en el hash y luego toma la segunda parte donde esperamos los parámetros
      return params;
    }

    const params = getHashParameters();
    const name = params.get("landLordName");
    if (name) {
      setLandLordName(decodeURIComponent(name.replace(/\+/g, " "))); // Decodificar y reemplazar '+' por espacios
    }
  }, []);

  return (
    <div className="WelcomeSection">
      <h3 className="mdLabel">
        Dear <span className="fBold">{landLordName}</span>,
      </h3>
      <h3 className="smLabel">
        We are delighted that you have chosen us to be part of your team. As our
        relationship grows, we are confident that you will see why Blu Realty &
        Investments is the right choice. Our cutting-edge technology will allow
        you to experience the most efficient and reliable service in the
        industry.
      </h3>
      <h3 className="smLabel">
        Our goal is to maintain your property performing in its best condition,
        appreciating in its value and giving you its highest returns. We thank
        you for the opportunity and look forward towards earning your business
        and trust.
      </h3>
    </div>
  );
}
