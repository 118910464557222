
export default function HeroSection() {
  const HeroImage = "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FHeroLanlordMAForm.svg?alt=media&token=0b5497a8-5cc8-4b2c-96b9-872c9ff620dd";
  const WhiteLogo = "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FBluWhite.svg?alt=media&token=96f91948-b124-42e8-b086-679cc682ea2a";
  return (
    <div className="content HeroSection">
      <img src={HeroImage} className="content-img" alt="HeroImage" />
      <div className="HeroSection_Logo">
        <img src={WhiteLogo} alt="Blu" />
      </div>
    </div>
  );
}
