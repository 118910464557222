import React from "react";
import "./BuyerBrokerAgreement.css";

const BuyerBrokerAgreement = () => {
  return (
    <section className="buyerBrokerAgreement__section">
      <div className="buyerBrokerAgreementImg">
        <button className="buyerBrokerAgreementBtn">
          Buyer Broker Agreement
        </button>
        <button className="buyerBrokerAgreementBtnBottom">Click Here</button>
      </div>
    </section>
  );
};

export default BuyerBrokerAgreement;
