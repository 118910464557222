import React, { useState } from "react";
import "./BluCares.css";
const BLU_Img_Mar_36 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_34.jpg?alt=media&token=f4ff7ad6-83ed-4330-83b8-00e397b4f017";
const BLU_Img_Mar_35 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_35.jpg?alt=media&token=1fe4164f-0e5a-43fa-8507-54bec6fa9331";
const BLU_Img_Mar_34 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_36.jpg?alt=media&token=28e4e2e4-5dce-42ea-996c-62ae0ecfc964";
const bluCaresLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FbluCaresLogo.svg?alt=media&token=6fc05b53-375d-4f18-876a-fea913b33978";
const FreeRecyclingIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FFreeRecyclingIcon.svg?alt=media&token=fd517a8c-ecb9-4567-a9cb-e0deb36a91d4";
const MailLithiumIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FMailLithiumIcon.svg?alt=media&token=3533bfe4-3a0e-4c61-a9f4-8d80366dcd79";
const NationalDropOffsIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FNationalDropOffsIcon.svg?alt=media&token=847e26dd-5239-4a43-a214-6e48c7c04d50";
const The_Best_Buy_Logo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FThe_Best_Buy_Logo.png?alt=media&token=10104c9b-fc03-4271-9102-b3414355f4db";
const The_Home_Depot_Logo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FThe_Home_Depot_Logo.jpg?alt=media&token=3729e406-f6f0-4bb5-9cd6-1c3d915ba67c";
const The_Lowes_Logo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FThe_Lowes_Logo.jpg?alt=media&token=cd1e8bdc-2584-417d-a663-e09669fb8e74";
const Amazon_Recycling_Logo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FAmazon_Recycling_Logo.png?alt=media&token=22991fda-28ef-4277-a07b-7f3bb719ef65";
const Battery_Solutions_Logo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBattery_Solutions_Logo.png?alt=media&token=c21c4d5c-d00b-4c84-a6af-7efd8e6ba931";
const Call_2_Recycle_Logo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FCall_2_Recycle_Logo.jpg?alt=media&token=cf3403b2-d932-4a96-b1d5-645bd5011746";
const Step1 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FStep1.svg?alt=media&token=c1825d26-c758-4759-9196-44b649142795";
const Step2 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FStep2.svg?alt=media&token=08f6fb31-c681-4111-b383-201c857691f5";
const Step3 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FStep3.svg?alt=media&token=bcfd08cb-beed-40d1-ae3f-457757e2b618";
const lets =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2Flets.jpg?alt=media&token=bcb8bb8a-fc88-43ae-9f13-7362bca9ec0d";
const letsCigarrillos =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FletsCigarrillos.jpg?alt=media&token=90d40f40-a80a-4481-b0ab-fba42d9c8888";

const BluCares = () => {
  const [activePopup, setActivePopup] = useState(null);
  const [showInnerPopup, setShowInnerPopup] = useState(null);
  const [activeStep, setActiveStep] = useState(null); // Definición del estado

  const togglePopup = (popupName) => {
    setActivePopup(activePopup === popupName ? null : popupName);
    setShowInnerPopup(null); // Cierra cualquier popup interno si se cambia de popup
  };

  const toggleInnerPopup = (popupName) => {
    setShowInnerPopup(showInnerPopup === popupName ? null : popupName);
  };
  const handleStepClick = (step) => {
    setActiveStep(step); // Actualizar el estado cuando se hace clic en un paso
  };

  return (
    <div className="BluCares-container">
      <img src={bluCaresLogo} alt="Blu Cares Logo" className="BluCares-logo" />
      <h1 className="BluCares-text">
        ENVIRONMENTAL AND SUSTAINABILITY SOLUTIONS
      </h1>

      <div className="BluCares-images-container">
        {/* Imagen 1: Popup de baterías de litio */}
        <div
          className="BluCares-image-block"
          onClick={() => togglePopup("lithiumPopup")}
        >
          <img
            src={BLU_Img_Mar_36}
            alt="Lithium Batteries"
            className="BluCares-image"
          />
          <p className="BluCares-image-text">Lithium Batteries</p>
        </div>

        {/* Imagen 2: Popup de Free Recycling Centers */}
        <div
          className="BluCares-image-block"
          onClick={() => togglePopup("freeRecyclingPopup")}
        >
          <img
            src={BLU_Img_Mar_35}
            alt="Free Recycling Centers"
            className="BluCares-image BluCares-image-cigarreteCones"
          />
          <p className="BluCares-image-text">Cigarrete Cones</p>
        </div>

        {/* Imagen 3: Popup de Let's Be Proud */}
        <div
          className="BluCares-image-block"
          onClick={() => togglePopup("letsBeProudPopup")}
        >
          <img
            src={BLU_Img_Mar_34}
            alt="Let's Be Proud"
            className="BluCares-image BluCares-image--letsBeProud"
          />
          <p className="BluCares-image-text">Let's Be Proud</p>
        </div>
      </div>

      <button className="BluCares-button">LEARN MORE</button>

      {/* Popup de baterías de litio */}
      {activePopup === "lithiumPopup" && (
        <div>
          <div
            className="popup-overlay"
            onClick={() => togglePopup("lithiumPopup")}
          ></div>
          <div class="popup-overlay"></div>
          <div className="popup-batteries">
            <div className="popup-content">
              <h2>Lithium Batteries</h2>
              <p>
                Maximizing the value of used lithium batteries depends on proper
                management, allowing for the recovery of key materials and
                reducing environmental impact.
              </p>
              <div className="popup-options">
                <div
                  className="popup-option"
                  onClick={() => toggleInnerPopup("freeRecycling")}
                >
                  <img
                    src={FreeRecyclingIcon}
                    alt="Free recycling centers"
                    className="popup-option-image"
                  />
                  <p>Free recycling centers</p>
                </div>
                <div
                  className="popup-option"
                  onClick={() => toggleInnerPopup("nationalDropOffs")}
                >
                  <img
                    src={NationalDropOffsIcon}
                    alt="National Drop-Offs"
                    className="popup-option-image"
                  />
                  <p>National Drop-Offs</p>
                </div>
                <div
                  className="popup-option"
                  onClick={() => toggleInnerPopup("mailLithium")}
                >
                  <img
                    src={MailLithiumIcon}
                    alt="Mail Lithium Batteries?"
                    className="popup-option-image"
                  />
                  <p>Mail Lithium Batteries?</p>
                </div>
              </div>
              <button
                className="popup-close"
                onClick={() => togglePopup("lithiumPopup")}
              >
                X
              </button>
            </div>

            {/* Popup interno de "Free Recycling Centers" */}
            {showInnerPopup === "freeRecycling" && (
              <div>
                <div
                  className="popup-overlay"
                  onClick={() => toggleInnerPopup("freeRecycling")}
                ></div>
                <div className="popup-recycling">
                  <div className="popup-recycling-content">
                    <h2>Lithium Battery Drop-Offs</h2>
                    <div className="recycling-centers-logos">
                      <div className="recycling-center">
                        <img
                          src={The_Home_Depot_Logo}
                          alt="The Home Depot"
                          className="recycling-logo"
                        />
                        <p>
                          <strong className="underline-strong">
                            The Home Depot
                          </strong>
                          <br />
                          Located near the entrance or customer service area.
                        </p>
                      </div>
                      <div className="recycling-center">
                        <img
                          src={The_Lowes_Logo}
                          alt="Lowe's"
                          className="recycling-logo"
                        />
                        <p>
                          <strong className="underline-strong">Lowe's</strong>
                          <br />
                          These bins are typically found near the entrance or
                          customer service area.
                        </p>
                      </div>
                      <div className="recycling-center">
                        <img
                          src={The_Best_Buy_Logo}
                          alt="Best Buy"
                          className="recycling-logo"
                        />
                        <p>
                          <strong className="underline-strong">Best Buy</strong>
                          <br />
                          Stores feature recycling kiosks at the entrance.
                          Batteries, electronic devices, and lithium batteries
                          are accepted.
                        </p>
                      </div>
                    </div>
                    <p className="recycling-note">
                      These centers work in partnership with{" "}
                      <a href="#">Call2Recycle</a>, one of the largest battery
                      recycling programs in the U.S. It’s a good idea to call
                      ahead to verify accepted types of batteries and confirm
                      details.
                    </p>
                    <button
                      className="popup-close"
                      onClick={() => toggleInnerPopup("freeRecycling")}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Popup interno de "National Drop-Offs" */}
            {showInnerPopup === "nationalDropOffs" && (
              <div>
                <div
                  className="popup-overlay"
                  onClick={() => toggleInnerPopup("nationalDropOffs")}
                ></div>
                <div className="popup-national">
                  <div className="popup-national-content">
                    <h2 className="styled-h2">National Drop-Offs</h2>
                    <p className="styled-paragraph">
                      These drop-offs ensure safe and responsible handling,
                      making the process easy.
                    </p>
                    <div className="drop-off-logos">
                      <div className="drop-off-center">
                        <img
                          src={Call_2_Recycle_Logo}
                          alt="Call2Recycle"
                          className="drop-off-logo"
                        />
                        <p>
                          <strong className="underline-strong">
                            Call2Recycle
                          </strong>
                          <br />
                          Free recycling at collection points nationwide.{" "}
                        </p>
                      </div>
                      <div className="drop-off-center">
                        <img
                          src={Battery_Solutions_Logo}
                          alt="Battery Solutions"
                          className="battery-solutions-logo"
                        />
                        <p>
                          <strong className="underline-strong">
                            Battery Solutions
                          </strong>
                          <br />
                          Provides recycling kits for national delivery and easy
                          drop-off.
                        </p>
                      </div>
                      <div className="drop-off-center">
                        <img
                          src={Amazon_Recycling_Logo}
                          alt="Amazon Recycling Program"
                          className="drop-off-logo"
                        />
                        <p>
                          <strong className="underline-strong">
                            Amazon Recycling Program
                          </strong>
                          <br />
                          Offers national drop-off points, with options for
                          mailing or in-store delivery.
                        </p>
                      </div>
                    </div>
                    <p className="national-note">
                      These centers work in partnership with{" "}
                      <a href="#">Call2Recycle</a>, one of the largest battery
                      recycling programs in the U.S. It is recommended to call
                      ahead to verify accepted battery types and confirm
                      details.
                    </p>
                    <button
                      className="popup-close"
                      onClick={() => toggleInnerPopup("nationalDropOffs")}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* aca empieza el pop up a terminar */}

            {/* Popup interno de "Mail Lithium Batteries?" */}
            {showInnerPopup === "mailLithium" && (
              <div>
                <div
                  className="divFix1"
                  onClick={() => toggleInnerPopup("mailLithium")}
                ></div>
                <div className="popup-national ">
                  <div className="divContenedor1">
                    <h2 className="styled-h2">
                      Lithium Battery Shipping Guide
                    </h2>

                    <div className="contenedorDeTodosLosPasos">
                      <div className="timeline-circle left"></div>{" "}
                      {/* Círculo izquierdo */}
                      <div className="timeline-line"></div>{" "}
                      {/* Línea que conecta los pasos */}
                      <div className="timeline-circle right"></div>{" "}
                      {/* Círculo derecho */}
                      {/* Paso 1 */}
                      <div
                        className={`contenedorDePasosIndividuales ${
                          activeStep === 1 ? "sombra-seleccionada" : ""
                        }`}
                        onClick={() => handleStepClick(1)}
                      >
                        <div className="contenedorDelLogo">
                          <img src={Step1} alt="Step 1" className="step-logo" />
                          {activeStep === 1 && (
                            <div className="textoDelPaso">
                              <p>
                                <strong>Request a Recycling Kit</strong>
                              </p>
                              <p>
                                Contact a recycling provider like Call2Recycle
                                or Battery Solutions and request a packaging kit
                                that includes prepaid shipping labels.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Paso 2 */}
                      <div
                        className={`contenedorDePasosIndividuales ${
                          activeStep === 2 ? "sombra-seleccionada" : ""
                        }`}
                        onClick={() => handleStepClick(2)}
                      >
                        <div className="contenedorDelLogo">
                          <img src={Step2} alt="Step 2" className="step-logo" />
                          {activeStep === 2 && (
                            <div className="textoDelPaso">
                              <p>
                                <strong>Pack the Batteries</strong>
                              </p>
                              <p>
                                Once you receive the kit, place the batteries
                                inside the provided packaging according to the
                                instructions. Ensure they are securely packed to
                                prevent any damage during transport.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Paso 3 */}
                      <div
                        className={`contenedorDePasosIndividuales ${
                          activeStep === 3 ? "sombra-seleccionada" : ""
                        }`}
                        onClick={() => handleStepClick(3)}
                      >
                        <div className="contenedorDelLogo">
                          <img
                            src={Step3}
                            alt="Step 3"
                            className="step-logo-3"
                          />
                          {activeStep === 3 && (
                            <div className="textoDelPaso">
                              <p>
                                <strong>Schedule the Pickup</strong>
                              </p>
                              <p>
                                Affix the prepaid shipping label in a visible
                                location. If shipping by air, include a label
                                indicating that it contains lithium batteries.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="popup-close"
                      onClick={() => {
                        toggleInnerPopup("mailLithium");
                        setActiveStep(1); // Vuelve al paso 1 cuando se cierra el popup
                      }}
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* aca TERMINA el pop up a terminar */}

      {activePopup === "freeRecyclingPopup" && (
        <div>
          {/* Reemplazamos la clase por popup-overlay para el fondo difuminado */}
          <div
            className="popup-overlay"
            onClick={() => togglePopup("freeRecyclingPopup")}
          ></div>
          <div className="freeRecycling-popup">
            <div className="freeRecycling-container">
              <img
                src={letsCigarrillos}
                alt="Free Recycling"
                className="freeRecycling-image"
              />

              {/* Timeline Positioned Outside */}
              <div className="timeline-container">
                <div className="step-circle">
                  <p>1</p>
                </div>
                <div className="step-line"></div>
                <div className="step-circle">
                  <p>2</p>
                </div>
                <div className="step-line"></div>
                <div className="step-circle">
                  <p>3</p>
                </div>
              </div>

              <div className="freeRecycling-text-overlay">
                <h3>Efficient Collection and Recycling Process:</h3>
                <div className="steps-text">
                  <p>
                    <strong>Collection:</strong> Installation of bins on the
                    beaches for disposing of cigarette butts. These devices help
                    keep the beaches clean and prevent butts from polluting the
                    ocean.
                  </p>
                  <p>
                    <strong>Recycling Process:</strong> Collected butts are
                    transported to specialized recycling centers. There, toxic
                    materials are separated and recyclable components are
                    reused.
                  </p>
                  <p>
                    <strong>Transformation:</strong> Recycled butts are
                    transformed into useful products, such as recycled plastic
                    or construction materials. A small action can make a
                    difference in the planet's sustainability.
                  </p>
                </div>
              </div>
            </div>
            <button
              className="popup-close"
              onClick={() => togglePopup("freeRecyclingPopup")}
            >
              X
            </button>
          </div>
        </div>
      )}

      {/* Popup de Let's Be Proud */}
      {activePopup === "letsBeProudPopup" && (
        <div>
          <div
            className="popup-overlay"
            onClick={() => togglePopup("letsBeProudPopup")}
          ></div>
          <div className="popup-lets">
            <div className="lets-container">
              <img src={lets} alt="lets" className="lets-image" />
              <div className="lets-text-overlay">
                <p>
                  We take pride in caring for our beaches and protecting the
                  environment. Trash collection is essential to preserving the
                  beauty of our oceans. Every small effort counts; together, we
                  can make a significant impact.
                </p>
                <div className="letsBeProudText"></div>
              </div>
              <button
                className="popup-close"
                onClick={() => togglePopup("letsBeProudPopup")}
              >
                X
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BluCares;
