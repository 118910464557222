import BluWorldHero from "./BluWorldHero/BluWorldHero";
import BeachCleanUp from "./BeachCleanUp/BeachCleanUp";
import StayInTouch from "./StayInTouch/StayInTouch";
import BluWorldElDoradoDiscovered from "./BluWorldElDoradoDiscovered/BluWorldElDoradoDiscovered";
import ObserverSection from "./ObserverSection/ObserverSection";
import Quotes from "../Quotes/Quotes";
import BluCares from "./BluCares/BluCares";



export default function BluWorld() {

  const BluHeroImg =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBluWorld_Waves.svg?alt=media&token=6be39f9b-24f8-4af8-923e-626d7a498874"


  return (
    <>
      {/* <img className="BluWorldHeroImg" src={BluHeroImg} /> */}
      <BluWorldHero />
      <BeachCleanUp />
      <BluWorldElDoradoDiscovered />
      <ObserverSection />
      <BluCares />
      <StayInTouch />
      <Quotes />
    </>
  );
}
