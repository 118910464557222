import React, { useState, useEffect } from "react";

const HomeownersAssociationSection = ({ onDataChange }) => {
  // Estado para el checkbox y visibilidad
  const [isChecked, setIsChecked] = useState(false);

  // Efecto para manejar la visibilidad del contenido
  useEffect(() => {
    const element = document.querySelector(".HomeownersCheckboxActived");
    if (isChecked) {
      element.classList.add("showElement");
      element.classList.remove("Hidden");
    } else {
      element.classList.add("Hidden");
      element.classList.remove("showElement");
    }
  }, [isChecked]);

  const [part10Data, setPart10Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart10Data({
      ...part10Data,
      [name]: value,
    });
    onDataChange({
      ...part10Data,
      [name]: value,
    });
  };

  return (
    <section className="insuranceRenters__Section">
      <div className="checkboxContainer">
        <label className="checkbox-label" htmlFor="Homeowners__Checkbox">
          Homeowner’s Association
          <input
            type="checkbox"
            id="Homeowners__Checkbox"
            name="Homeowners__Checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)} // Cambia el estado al hacer clic
          />
          <span className="checkmark"></span>
        </label>
      </div>

      <div className="HomeownersCheckboxActived Hidden">
        <div className="uploadFileContainer">
          <svg
            fill="#ffffff"
            height="24px"
            width="24px"
            viewBox="0 0 330.591 330.591"
          >
            <path
              d="M52.575,320.395c-0.693,0-1.391-0.015-2.09-0.043c-12.979-0.54-25.361-6.071-34.865-15.576
              c-9.504-9.504-15.035-21.886-15.576-34.864c-0.549-13.213,4.115-25.456,13.133-34.475L221.581,27.033
              c11.523-11.523,27.197-17.483,44.096-16.78c16.676,0.693,32.594,7.81,44.822,20.037c12.228,12.229,19.346,28.147,20.037,44.823
              c0.703,16.911-5.256,32.571-16.781,44.096L156.711,276.255c-2.928,2.927-7.676,2.928-10.607,0
              c-2.928-2.93-2.928-7.678,0-10.608l157.045-157.047c8.523-8.522,12.928-20.194,12.4-32.865
              c-0.537-12.906-6.098-25.279-15.658-34.84c-9.559-9.56-21.932-15.119-34.838-15.656c-12.67-0.533-24.344,3.876-32.865,12.399
              L23.784,246.044c-12.596,12.594-11.498,34.184,2.443,48.125c6.836,6.837,15.672,10.813,24.881,11.195
              c8.975,0.349,17.229-2.734,23.244-8.752l169.441-169.439c7.422-7.422,6.691-20.229-1.629-28.549
              c-4.113-4.114-9.414-6.505-14.924-6.733c-5.289-0.212-10.115,1.595-13.625,5.106L95.536,215.08
              c-2.93,2.927-7.678,2.928-10.607,0c-2.93-2.93-2.93-7.678,0-10.607L203.008,86.39c6.512-6.512,15.322-9.9,24.855-9.486
              c9.281,0.385,18.127,4.332,24.906,11.114c14.17,14.167,14.9,36.49,1.631,49.762L84.959,307.22
              C76.418,315.76,64.985,320.395,52.575,320.395z"
            />
          </svg>
          <input type="file" placeholder="none" className="uploadFile" />
          <span>Upload File</span>
        </div>

        <div className="insuranceMortgageInputs">
          <div className="inputOne">
            <label
              htmlFor="Homeowner_s_Association_Bank"
              className="sectionTwelve__Label"
            >
              Name of the Bank:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOABank"
              name="Homeowner_s_Association_Bank"
              onChange={handleChange}
            />
          </div>
          <div className="inputTwo">
            <label
              htmlFor="Homeowner_s_Association_Account"
              className="sectionTwelve__Label"
            >
              Mortgage Account:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOAAccount"
              name="Homeowner_s_Association_Account"
              onChange={handleChange}
            />
          </div>
          <div className="inputThree">
            <label
              htmlFor="Homeowner_s_Association_State"
              className="sectionTwelve__Label"
            >
              State:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOAState"
              name="Homeowner_s_Association_State"
              onChange={handleChange}
            />
          </div>
          <div className="inputFour">
            <label
              htmlFor="Homeowner_s_Association_City"
              className="sectionTwelve__Label"
            >
              City:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOACity"
              name="Homeowner_s_Association_City"
              onChange={handleChange}
            />
          </div>
          <div className="inputFive">
            <label
              htmlFor="Homeowner_s_Association_Address"
              className="sectionTwelve__Label"
            >
              Address:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOAAddress"
              name="Homeowner_s_Association_Address"
              onChange={handleChange}
            />
          </div>
          <div className="inputSix">
            <label
              htmlFor="Homeowner_s_Association_Zip_Code"
              className="sectionTwelve__Label"
            >
              Zipcode:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOAZipcode"
              name="Homeowner_s_Association_Zip_Code"
              onChange={handleChange}
            />
          </div>
          <div className="inputSeven">
            <label
              htmlFor="Homeowner_s_Association_Amount_to_be_paid"
              className="sectionTwelve__Label"
            >
              Amount to be paid:
            </label>
            <input
              type="text"
              className="sectionTwelve__Input"
              id="insuranceHOAAmount"
              name="Homeowner_s_Association_Amount_to_be_paid"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeownersAssociationSection;
