import style from "./ObserverGalery.module.css";
const University =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_32.jpg?alt=media&token=eb13f65d-c204-4d9f-9de7-f3c67cf7f4a2";
const Student =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_31.jpg?alt=media&token=e4f3d4c3-5f36-40a9-b8a5-f0e16238bd5c";
const Boy =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_May_10.jpg?alt=media&token=aa56b07f-7e8a-4d9b-97b7-5cd09952b04a";

function ObserverGalery() {
  return (
    <div className={style.imagesContainer}>
      <div className={style.imageContainer}>
        <img className={style.image} src={Boy} alt="Boy" />
      </div>
      <div className={style.imageContainer}>
        <img className={style.imageLeft} src={Student} alt="Student" />
      </div>
      <div className={style.imageContainer}>
        <img className={style.image} src={University} alt="University" />
      </div>
    </div>
  );
}

export default ObserverGalery;
