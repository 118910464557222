import React from "react";

export default function LetterOwnerGranting({ handlePrint }) {
  const PrintIcon =
    "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FPrintIconMAForm.svg?alt=media&token=f0208fdd-2411-4565-9f88-031decbce881";
  const NotarizedIcon =
    "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FNotarizedIcon.svg?alt=media&token=46fb81c7-f320-47fc-9ca6-72920f0f4867";
  const SendIcon =
    "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FSendIconMAFormHand.svg?alt=media&token=b1b83935-7f85-43c7-90ad-8c017cff5376";
  return (
    <div className="LetterOwnerGranting">
      <div className="LetterOwnerGranting_Icons">
        <div className="LetterOwnerGranting_Icon" onClick={handlePrint}>
          <img src={PrintIcon} alt="Print Icon" />
          <p>Print</p>
        </div>
        <div className="LetterOwnerGranting_Icon">
          <img src={NotarizedIcon} alt="Notarized Icon" />
          <p>Notarized</p>
        </div>
        <div className="LetterOwnerGranting_Icon">
          <img src={SendIcon} alt="Send Icon" />
          <p>Send</p>
        </div>
      </div>
      <h4>
        LETTER FROM PROPERTY OWNER GRANTING <br /> AUTHORIZATION TO ACT
      </h4>
      <h3>To whom it may concern:</h3>
      <div className="dInline">
        <h3>I, </h3>
        <input
          type="text"
          placeholder="Name of the LLC that gives authorization"
          name="Letter_NameLLCInput"
          id="Letter_NameLLCInput"
        />
        <h3>
          , authorize Blu Realty & Investments, LLC of Florida to act on my
          behalf regarding uncontested residential tenant evictions, request of
          documents, receiving confidential information, making payments and
          making administrative changes when needed. Further, I hereby grant
          general power to Blu Realty & Investments, LLC to represent me in all
          real property matters concerning this property including, but not
          limited to: voting, proxies, licensing, certificates, taxation,
          open/start/close/stop utility accounts/services, etc. for the property
          located at:
        </h3>
      </div>
      <h3>
        <br /> This written authorization is effective the date signed and will
        remain in effect for a FIVE-YEAR period from the date signed below. I
        hereby, being duly sworn, declare that I am the owner of the property
        listed above.
      </h3>
      <h3>Thank you kindly,</h3>
      <input
        type="text"
        className="signatureLetter"
        id="Letter_SignaturePropertyOwnerInput"
        name="Letter_SignaturePropertyOwnerInput"
      />
      <div className="dFlex jS">
        <label htmlFor="Letter_SignaturePropertyOwnerInput">
          <h3>Signature of Property Owner</h3>
        </label>
        <h3>Print, Type or Notary Seal</h3>
      </div>
      <div className="dInline Checkboxes">
        <h3>The foregoing Affidavit was acknowledged before me by means of</h3>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Letter_PhysicalPresenceInput"
          >
            <input
              type="checkbox"
              id="Letter_PhysicalPresenceInput"
              name="Letter_PhysicalPresenceInput"
              value="Physical Presence"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <label htmlFor="Letter_PhysicalPresenceInput">
          <h3>physical presence or </h3>
        </label>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Letter_OnlineNotarizationInput"
          >
            <input
              type="checkbox"
              id="Letter_OnlineNotarizationInput"
              name="Letter_OnlineNotarizationInput"
              value="Online Notarization"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <h3>
          {" "}
          <label htmlFor="Letter_OnlineNotarizationInput">
            online notarization
          </label>
          , this ______ day of ______________ 20___ by the affiant is
        </h3>
        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="Letter_PersonallyKnow">
            <input
              type="checkbox"
              id="Letter_PersonallyKnow"
              name="Letter_PersonallyKnow"
              value="Personally Know to Me"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <label htmlFor="Letter_PersonallyKnow">
          <h3>personally known to me or </h3>
        </label>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Letter_HasProducedAsIdentificationInput"
          >
            <input
              type="checkbox"
              id="Letter_HasProducedAsIdentificationInput"
              name="Letter_HasProducedAsIdentificationInput"
              value="Has Produced as Identification"
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <label htmlFor="Letter_HasProducedAsIdentificationInput">
          <h3> has produced </h3>
        </label>{" "}
        <input type="text" /> <h3>as identification.</h3>
      </div>
      <input
        type="text"
        className="signatureLetter"
        id="Letter_SinatureNotaryInput"
        name="Letter_SinatureNotaryInput"
      />
      <div className="dFlex jS">
        <label htmlFor="Letter_SinatureNotaryInput">
          <h3>Signature of Notary</h3>
        </label>
        <h3>Print, Type or Notary Seal</h3>
      </div>
      <div className="dInline Owner">
        <label htmlFor="Letter_OwnerNameInput">
          <h3>Owner</h3>
        </label>{" "}
        <input
          type="text"
          id="Letter_OwnerNameInput"
          name="Letter_OwnerNameInput"
        />{" "}
        <h3>
          and <label htmlFor="Letter_ManagerNameInput">Manager</label>
        </h3>{" "}
        <input
          type="text"
          id="Letter_ManagerNameInput"
          name="Letter_ManagerNameInput"
        />{" "}
        <h3>
          acknowledge receipt of a copy of this page Blu Realty & Investents .
        </h3>
      </div>
      <span className="bluLetter"># BLUTRANQUILITY</span>
    </div>
  );
}
