export default function FooterMAForm() {
  return (
    <div className="footer-container">
      <div className="footer-container-a">
        <div className="footer-container-a-overlay">
          <h1 className="footer-h1">TRANQUILITY</h1>
        </div>
      </div>
      <div className="footer-container-b ">
        <h2 className="footer-h2">Your attitude, not your aptitude, will</h2>
        <span className="footer-span">Zig Ziglar</span>
      </div>
    </div>
  );
}
