import { React, useState, useRef, useEffect } from "react";
import ObserverBody from "./ObserverBody/ObserverBody";
import ObserverGalery from "./ObserverGalery/ObserverGalery";
import ObserverHead from "./ObserverHead/ObserverHead";
import style from "./ObserverSection.module.css";
import ObserverPopUp from "./ObserverPopUp/ObserverPopUp";

function ObserverSection() {
  const [popUp, setPopUp] = useState(false);

  const popUpRef = useRef(null); // Referencia para el popup

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popUp &&
        popUpRef.current &&
        !popUpRef.current.contains(event.target)
      ) {
        setPopUp(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp]);

  return (
    <div className={style.observerContainer}>
      {popUp && (
        <div ref={popUpRef}>
          <ObserverPopUp setPopUp={setPopUp} />
        </div>
      )}
      <ObserverHead />
      <ObserverBody popUp={popUp} setPopUp={setPopUp} />
      <ObserverGalery />
    </div>
  );
}

export default ObserverSection;
