import { React, useState } from "react";

const InsuranceSection = ({ onDataChange, onDataChange1 }) => {
  const [part6Data, setPart6Data] = useState({});
  const [warrantiesPart, setWarrantiesPart] = useState({});
  const [selectedInsurance, setSelectedInsurance] = useState({
    Insurance__CheckboxInsuranceProperty: false,
    Insurance__CheckboxRenters: false,
    Insurance__CheckboxExcessLiability: false,
    Insurance__CheckboxWarranties: false,
  });

  const handleChange1 = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Actualiza solo el estado de los checkboxes sin afectar los datos del formulario
      setSelectedInsurance((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      // Actualiza los datos que se enviarán al formulario
      setPart6Data({
        ...part6Data,
        [name]: value,
      });

      onDataChange({
        ...part6Data,
        [name]: value,
      });
    }
  };
  const handleChange2 = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Actualiza solo el estado de los checkboxes sin afectar los datos del formulario
      setSelectedInsurance((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      // Actualiza los datos que se enviarán al formulario
      setWarrantiesPart({
        ...warrantiesPart,
        [name]: value,
      });

      onDataChange1({
        ...warrantiesPart,
        [name]: value,
      });
    }
  };

  return (
    <section className="Insurance__Section">
      <div className="dFlex spaceBet">
        <h3 className="InsuranceType">Insurance Type</h3>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Insurance__CheckboxInsuranceProperty"
          >
            Property
            <input
              type="checkbox"
              id="Insurance__CheckboxInsuranceProperty"
              name="Insurance__CheckboxInsuranceProperty"
              onChange={handleChange1}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Insurance__CheckboxRenters"
          >
            Renter's
            <input
              type="checkbox"
              id="Insurance__CheckboxRenters"
              name="Insurance__CheckboxRenters"
              onChange={handleChange1}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Insurance__CheckboxExcessLiability"
          >
            Excess Liability
            <input
              type="checkbox"
              id="Insurance__CheckboxExcessLiability"
              name="Insurance__CheckboxExcessLiability"
              onChange={handleChange1}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Insurance__CheckboxWarranties"
          >
            Warranties
            <input
              type="checkbox"
              id="Insurance__CheckboxWarranties"
              name="Insurance__CheckboxWarranties"
              onChange={handleChange1}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>

      {/* Property Insurance */}
      <div
        className={`insuranceContainerGrid ${
          !selectedInsurance.Insurance__CheckboxInsuranceProperty
            ? "Hidden"
            : ""
        }`}
        id="insuranceProperty"
      >
        <h3 className="insuranceTitle">Property Insurance:</h3>
        <div className="inputOne">
          <label
            htmlFor="Insurance_Property_Provider_Name"
            className="sectionTwelve__Label"
          >
            Provider Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insurancePropertyProviderName"
            name="Insurance_Property_Provider_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputTwo">
          <label
            htmlFor="Insurance_Property_Agent_Name"
            className="sectionTwelve__Label"
          >
            Agent Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insurancePropertyAgentName"
            name="Insurance_Property_Agent_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputThree">
          <label
            htmlFor="Insurance_Property_Policy"
            className="sectionTwelve__Label"
          >
            Policy Number:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insurancePropertyPolicyNumber"
            name="Insurance_Property_Policy"
            onChange={handleChange1}
          />
        </div>
        <div className="inputFour">
          <label
            htmlFor="Insured_Property_Name"
            className="sectionTwelve__Label"
          >
            Insured Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Insured_Property_Name"
            name="Insured_Property_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputFive">
          <label
            htmlFor="Insurance_Property_Website"
            className="sectionTwelve__Label"
          >
            Website:
          </label>
          <input
            type="url"
            className="sectionTwelve__Input"
            id="insurancePropertyWebsite"
            name="Insurance_Property_Website"
            onChange={handleChange1}
          />
        </div>
        <div className="inputSix">
          <label
            htmlFor="Insurance_Property_User_Name"
            className="sectionTwelve__Label"
          >
            Username:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insurancePropertyUsername"
            name="Insurance_Property_User_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputSeven">
          <label
            htmlFor="Insurance_Property_Password"
            className="sectionTwelve__Label"
          >
            Password:
          </label>
          <input
            type="password"
            className="sectionTwelve__Input"
            id="insurancePropertyPassword"
            name="Insurance_Property_Password"
            onChange={handleChange1}
          />
        </div>
      </div>

      {/* Renters Insurance */}
      <div
        className={`insuranceContainerGrid ${
          !selectedInsurance.Insurance__CheckboxRenters ? "Hidden" : ""
        }`}
        id="insuranceRenters"
      >
        <h3 className="insuranceTitle">Renter's Insurance:</h3>
        <div className="inputOne">
          <label htmlFor="Renter_s_Provider" className="sectionTwelve__Label">
            Provider Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceRentersProviderName"
            name="Renter_s_Provider"
            onChange={handleChange1}
          />
        </div>
        <div className="inputTwo">
          <label htmlFor="Renter_s_Agent_Name" className="sectionTwelve__Label">
            Agent Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceRentersAgentName"
            name="Renter_s_Agent_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputThree">
          <label
            htmlFor="Renter_s_Police_Number"
            className="sectionTwelve__Label"
          >
            Policy Number:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceRentersPolicyNumber"
            name="Renter_s_Police_Number"
            onChange={handleChange1}
          />
        </div>
        <div className="inputFour">
          <label
            htmlFor="Renter_s_Insured_Name"
            className="sectionTwelve__Label"
          >
            Insured Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceRentersInsuredName"
            name="Renter_s_Insured_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputFive">
          <label htmlFor="Renter_s_Website" className="sectionTwelve__Label">
            Website:
          </label>
          <input
            type="url"
            className="sectionTwelve__Input"
            id="insuranceRentersWebsite"
            name="Renter_s_Website"
            onChange={handleChange1}
          />
        </div>
        <div className="inputSix">
          <label htmlFor="Renter_s_Username" className="sectionTwelve__Label">
            Username:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceRentersUsername"
            name="Renter_s_Username"
            onChange={handleChange1}
          />
        </div>
        <div className="inputSeven">
          <label htmlFor="Renter_s_Password" className="sectionTwelve__Label">
            Password:
          </label>
          <input
            type="password"
            className="sectionTwelve__Input"
            id="insuranceRentersPassword"
            name="Renter_s_Password"
            onChange={handleChange1}
          />
        </div>
      </div>

      {/* Excess Liability */}
      <div
        className={`insuranceContainerGrid ${
          !selectedInsurance.Insurance__CheckboxExcessLiability ? "Hidden" : ""
        }`}
        id="insuranceExcessLiability"
      >
        <h3 className="insuranceTitle">Excess Liability:</h3>
        <div className="inputOne">
          <label
            htmlFor="Excess_Liability_Provider_Name"
            className="sectionTwelve__Label"
          >
            Provider Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceExcessLiabilityProviderName"
            name="Excess_Liability_Provider_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputTwo">
          <label
            htmlFor="Excess_Liability_Agent_Name"
            className="sectionTwelve__Label"
          >
            Agent Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceExcessLiabilityAgentName"
            name="Excess_Liability_Agent_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputThree">
          <label
            htmlFor="Excess_Liability_Police_Number"
            className="sectionTwelve__Label"
          >
            Policy Number:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceExcessLiabilityPolicyNumber"
            name="Excess_Liability_Police_Number"
            onChange={handleChange1}
          />
        </div>
        <div className="inputFour">
          <label
            htmlFor="Excess_Liability_Insured_Name"
            className="sectionTwelve__Label"
          >
            Insured Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Excess_Liability_Insured_Name"
            name="Excess_Liability_Insured_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputFive">
          <label
            htmlFor="Excess_Liability_Website"
            className="sectionTwelve__Label"
          >
            Website:
          </label>
          <input
            type="url"
            className="sectionTwelve__Input"
            id="insuranceExcessLiabilityWebsite"
            name="Excess_Liability_Website"
            onChange={handleChange1}
          />
        </div>
        <div className="inputSix">
          <label
            htmlFor="Excess_Liability_User_Name"
            className="sectionTwelve__Label"
          >
            Username:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="insuranceExcessLiabilityUsername"
            name="Excess_Liability_User_Name"
            onChange={handleChange1}
          />
        </div>
        <div className="inputSeven">
          <label
            htmlFor="Excess_Liability_Password"
            className="sectionTwelve__Label"
          >
            Password:
          </label>
          <input
            type="password"
            className="sectionTwelve__Input"
            id="insuranceExcessLiabilityPassword"
            name="Excess_Liability_Password"
            onChange={handleChange1}
          />
        </div>
      </div>

      {/* Warranties */}
      <div
        className={`insuranceContainerGrid ${
          !selectedInsurance.Insurance__CheckboxWarranties ? "Hidden" : ""
        }`}
        id="insuranceWarranties"
      >
        <h3 className="insuranceTitle">Warranties:</h3>
        <div className="inputOne">
          <label
            htmlFor="Warranties_Provider_Name"
            className="sectionTwelve__Label"
          >
            Provider Name:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Warranties_Provider_Name"
            name="Warranties_Provider_Name"
            onChange={handleChange2}
          />
        </div>
        <div className="inputTwo">
          <label htmlFor="Warranties_Make" className="sectionTwelve__Label">
            Make:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Warranties_Make"
            name="Warranties_Make"
            onChange={handleChange2}
          />
        </div>
        <div className="inputThree">
          <label
            htmlFor="Warranties_Policy_Number"
            className="sectionTwelve__Label"
          >
            Policy Number:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Warranties_Policy_Number"
            name="Warranties_Policy_Number"
            onChange={handleChange2}
          />
        </div>
        <div className="inputFour">
          <label htmlFor="Warranties_Model" className="sectionTwelve__Label">
            Model:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Warranties_Model"
            name="Warranties_Model"
            onChange={handleChange2}
          />
        </div>
        <div className="inputFive">
          <label htmlFor="Warranties_Website" className="sectionTwelve__Label">
            Website:
          </label>
          <input
            type="url"
            className="sectionTwelve__Input"
            id="Warranties_Website"
            name="Warranties_Website"
            onChange={handleChange2}
          />
        </div>
        <div className="inputSix">
          <label htmlFor="Warranties_Username" className="sectionTwelve__Label">
            Username:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Warranties_Username"
            name="Warranties_Username"
            onChange={handleChange2}
          />
        </div>
        <div className="inputSeven">
          <label htmlFor="Warranties_Password" className="sectionTwelve__Label">
            Password:
          </label>
          <input
            type="password"
            className="sectionTwelve__Input"
            id="Warranties_Password"
            name="Warranties_Password"
            onChange={handleChange2}
          />
        </div>
      </div>
    </section>
  );
};

export default InsuranceSection;
