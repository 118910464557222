import React from "react";

const FinalButtonSection = ({ onSubmit }) => {
  return (
    <section className="finalButton__Section">
      <button type="submit" id="submitButton" onClick={onSubmit}>
        Review
      </button>
    </section>
  );
};

export default FinalButtonSection;
