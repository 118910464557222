import React from "react";
import style from "./CloseButton.module.css";
const X =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FXbutton.svg?alt=media&token=3be5c185-b036-4a4e-bc4e-b4d2d74f84fc";

function CloseButton({ setPopUp }) {
  return (
    <button className={style.buttoncontainer} onClick={() => setPopUp(false)}>
      <img src={X} alt="close" />
    </button>
  );
}

export default CloseButton;
