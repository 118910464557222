import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
const WarningIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FWarning.svg?alt=media&token=72600b7b-7adf-4744-9796-8d14cf988910";
const CloseIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FxBlueLight.svg?alt=media&token=4ea13097-c450-4f2d-a4b1-4f0515aef0ab";
const EmailIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FEmail.svg?alt=media&token=b09301e0-7a71-460b-9c56-5a842ca5c022";
const WhatsAppIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FWhatsApp.svg?alt=media&token=25a3886b-1591-465d-9c69-db6a04cadc05";
const CallIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FCall.svg?alt=media&token=e4031157-9980-4008-9027-c1a9429ac949";
const FacebookIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FFacebook.svg?alt=media&token=abc2e8b7-f6ac-48ba-9165-c7551832891a";
const LinkedInIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FLinkedin.svg?alt=media&token=ea6450be-b7d3-4c53-ac52-42d29b8d56fb";
const YouTubeIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FYoutube.svg?alt=media&token=ca4be691-fabe-4574-9863-d7874600d99d";
const InstagramIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FInstagram.svg?alt=media&token=13140d3d-7b09-4287-9a12-2d5893a82cde";
const XIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FX.svg?alt=media&token=a6a2de6e-9338-4a1f-a05b-177fc7a5795e";
const PinterestIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FPinterest.svg?alt=media&token=17fdd425-4fe5-4618-a8a2-76e3d08deb88";
const TopArrowIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FtopArrow.svg?alt=media&token=9491806c-cee6-4795-a6e0-a3006282740b";

const Footer = () => {
  const [showSmarterPopup, setShowSmarterPopup] = useState(false);

  const showSmarterDecisionsPopUp = () => {
    setShowSmarterPopup(true);
  };

  const hideSmarterDecisionsPopUp = () => {
    setShowSmarterPopup(false);
  };

  useEffect(() => {
    const footerTitle = document.querySelector(".footer__title");
    footerTitle.addEventListener("mouseover", showSmarterDecisionsPopUp);

    return () => {
      footerTitle.removeEventListener("mouseover", showSmarterDecisionsPopUp);
    };
  }, []);

  return (
    <footer className="footer">
      {/* RIGHT WAVES */}
      <div className="footer__linesRight"></div>

      {/* TOP WAVES */}
      <div className="footerWaves"></div>

      {/* Footer Container */}
      <div className="footer__container">
        <div className="footer__header">
          <div className="footer_lineLeft"></div>

          {/* Popup para Smarter Decisions */}
          <div
            className={`smarterDecisionsPopUp ${
              showSmarterPopup ? "showPopup" : ""
            }`}
          >
            <div className="smarterDecisionsWarning">
              <img src={WarningIcon} alt="Warning" draggable="false" />
            </div>
            <button
              className="closeSmarter"
              onClick={hideSmarterDecisionsPopUp}
            >
              <img
                src={CloseIcon}
                alt="Close Smarter Decisions PopUp"
                draggable="false"
              />
            </button>
            <div className="smarterDecisionsPopUpContent">
              <form action="#" className="smarterDecisionsForm" method="post">
                <h3 className="smarterDecisionsTitle">
                  Make Smarter Decisions!
                </h3>
                <h2 className="smarterDecisionsSubtitle">Sign up!</h2>
                <h4>receive in-depth reports with detailed:</h4>
                <div className="smarterDecisionsCheckboxs">
                  <input
                    type="checkbox"
                    name="smartCheckbox1"
                    id="smartCheckbox1"
                    className="smarterDecisionsCheckbox"
                  />
                  <label htmlFor="smartCheckbox1">ROI Analysis</label>
                  <input
                    type="checkbox"
                    name="smartCheckbox2"
                    id="smartCheckbox2"
                    className="smarterDecisionsCheckbox"
                  />
                  <label htmlFor="smartCheckbox2">Cap Rate</label>
                  <input
                    type="checkbox"
                    name="smartCheckbox3"
                    id="smartCheckbox3"
                    className="smarterDecisionsCheckbox"
                  />
                  <label htmlFor="smartCheckbox3">Cash-on-Cash Return</label>
                </div>
                <p>
                  Our insights empower you to identify high-potential properties
                  and maximize your returns.
                </p>
                <input
                  type="email"
                  name="smarterDecisionsEmail"
                  id="smarterDecisionsEmail"
                  placeholder="yourmailadress@here.com"
                />
                <input
                  type="submit"
                  value="Subscribe"
                  className="smarterDecisionsButton"
                />
              </form>
            </div>
          </div>

          {/* Título del Footer */}
          <p className="footer__title">Investor Report</p>

          <div className="footer_lineRight"></div>
        </div>

        <div className="footerFlex">
          {/* Footer Pages Links */}
          <div className="footer__links">
            <ul>
              <li>
                <Link
                  to="/market-report"
                  className="footer__link"
                  draggable="false"
                >
                  Market Report
                </Link>
              </li>
              <li>
                <Link
                  to="/concierge"
                  className="footer__link"
                  draggable="false"
                >
                  Concierge
                </Link>
              </li>
              <li>
                <Link to="/storm" className="footer__link" draggable="false">
                  Storm
                </Link>
              </li>
              <li>
                <Link
                  to="/management"
                  className="footer__link footer__link--bold"
                  draggable="false"
                >
                  Management
                </Link>
              </li>
              <li>
                <Link
                  to="/buying"
                  className="footer__link footer__link--bold"
                  draggable="false"
                >
                  Buying
                </Link>
              </li>
              <li>
                <Link
                  to="/selling"
                  className="footer__link footer__link--bold"
                  draggable="false"
                >
                  Selling
                </Link>
              </li>
              <li>
                <Link to="/about-us" className="footer__link" draggable="false">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/bluworld" className="footer__link" draggable="false">
                  BluWorld
                </Link>
              </li>
              <li>
                <Link
                  to="/marine-lifestyle"
                  className="footer__link"
                  draggable="false"
                >
                  Miami Marine Lifestyle
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Links */}
          <div className="footer__contact-links">
            <a
              href="mailto:followus@blurealty.ai"
              className="footer__contact-link"
              draggable="false"
            >
              <div className="footer__contact">
                <div className="footer__contact-icon">
                  <img src={EmailIcon} alt="Email of Blu" draggable="false" />
                </div>
                <p>followus@blurealty.ai</p>
              </div>
            </a>
            <a
              href="https://wa.me/3058002587"
              className="footer__contact-link"
              draggable="false"
            >
              <div className="footer__contact">
                <div className="footer__contact-icon">
                  <img
                    src={WhatsAppIcon}
                    alt="WhatsApp of Blu"
                    draggable="false"
                  />
                </div>
                <div className="footer__contactNumber">
                  <p>305.800.BLUR</p>
                  <p className="footer__contactNumberFour">(2587)</p>
                </div>
              </div>
            </a>
            <a
              href="tel:+13058002587"
              className="footer__contact-link"
              draggable="false"
            >
              <div className="footer__contact">
                <div className="footer__contact-icon">
                  <img
                    src={CallIcon}
                    alt="Book a Call Icon whit Blu"
                    draggable="false"
                  />
                </div>
                <p>Book a Call</p>
              </div>
            </a>
          </div>
        </div>

        {/* Social Icons */}
        <div className="footer__social">
          <a
            href="https://www.facebook.com"
            className="footer__social-link"
            draggable="false"
          >
            <div className="footer__social-link--bluelight">
              <img
                src={FacebookIcon}
                alt="Go to Blu's Facebook"
                style={{ width: "55%" }}
                draggable="false"
              />
            </div>
          </a>
          <a
            href="https://www.linkedin.com"
            className="footer__social-link"
            draggable="false"
          >
            <div className="footer__social-link--bluelight">
              <img
                src={LinkedInIcon}
                alt="Go to Blu's Linkedin"
                style={{ width: "50%" }}
                draggable="false"
              />
            </div>
          </a>
          <a
            href="https://www.youtube.com"
            className="footer__social-link"
            draggable="false"
          >
            <div className="footer__social-link--bluelight">
              <img
                src={YouTubeIcon}
                alt="Go to Blu's Youtube"
                style={{ width: "60%" }}
                draggable="false"
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com"
            className="footer__social-link"
            draggable="false"
          >
            <div className="footer__social-link--bluelight">
              <img
                src={InstagramIcon}
                alt="Go to Blu's Instagram"
                style={{ width: "55%" }}
                draggable="false"
              />
            </div>
          </a>
          <a
            href="https://www.twitter.com"
            className="footer__social-link"
            draggable="false"
          >
            <div className="footer__social-link--bluelight">
              <img src={XIcon} alt="Go to Blu's X" draggable="false" />
            </div>
          </a>
          <a
            href="https://www.pinterest.com"
            className="footer__social-link"
            draggable="false"
          >
            <div className="footer__social-link--bluelight">
              <img
                src={PinterestIcon}
                alt="Go to Blu's Pinterest"
                style={{ width: "60%" }}
                draggable="false"
              />
            </div>
          </a>
        </div>

        {/* Back to Top Arrow */}
        <a href="#Top" className="hvr-bob" draggable="false">
          <div className="footer__arrow hvr-bob">
            <img src={TopArrowIcon} alt="Back to Top" draggable="false" />
          </div>
        </a>

        {/* Left Waves */}
        <div className="footer__linesLeft"></div>

        {/* Hashtags */}
        <div className="footer__hashtags">
          <h3 className="footer__hashtag">#blutranquility #blurealtyai</h3>
        </div>

        {/* Bottom Info */}
        <div className="footer__footer">
          <ul>
            <div className="liFlex">
              <li>
                <Link to="/privacy-policy" draggable="false">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-and-conditions" draggable="false">
                  Terms & Conditions
                </Link>
              </li>
            </div>
            <div className="liFlex Address">
              <li>
                <p>323 Sunny Isles Boulevard, 7th Floor</p>
              </li>
              <li>
                <p>Sunny Isles Beach, Florida 33160</p>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
