import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
const BluLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FBlu.svg?alt=media&token=ff74b194-9318-4a75-80d2-ad41864cbbce";
const BluWhiteLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FBluWhite.svg?alt=media&token=3cc5840f-1ea3-4b12-92c1-61303a0b940e";
const UserIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2Fuser.svg?alt=media&token=0d0e9044-9341-47c4-908e-827d0c3df0ef";
const SearchIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FSearch.svg?alt=media&token=230661e8-ece3-4ef1-b6d8-d2ef8a60f27c";
const SearchBarIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FsearchBarIcon.svg?alt=media&token=4b82c549-dcd4-4897-b832-cadaf24d42f3";
const BurgerIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FBurgerIcon.svg?alt=media&token=8c068e09-9f41-4629-a367-d6c0d0582af0";

const Header = () => {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const location = useLocation(); // Accedemos al path actual

  useEffect(() => {
    const searchLi = document.getElementById("searchLi");
    const searchButton = document.getElementById("searchButton");
    const searchBar = document.getElementById("searchBar");
    const searchInput = searchBar?.querySelector("input");
    const searchBarIcon = document.getElementById("searchBarIcon");
    const Header = document.getElementById("Top");
    const HeaderLogo = document.getElementById("HeaderLogo");

    // Altura Dinamica segun la Ruta
    if (Header && HeaderLogo) {
      if (location.pathname === "/blu-world") {
        HeaderLogo.style.width = "100px";
        Header.style.height = "120px"; // En BluWorld usamos 120px de altura
      } else {
        HeaderLogo.style.width = "120px";
        Header.style.height = "150px"; // Por defecto, para otras rutas usamos 150px
      }
    }

    function showSearchBar() {
      if (searchLi && searchBar && searchInput && searchBarIcon && HeaderLogo) {
        searchLi.style.display = "none";
        searchBar.style.display = "block";
        searchBar.classList.add("slideDown");
        searchBar.classList.remove("slideUp");
        Header.style.height = "210px"; // Cuando se muestra el SearchBar en el Header la altura cambia a 210px
        searchBar.style.width = "60%";
        HeaderLogo.style.width = "180px";
        searchInput.placeholder = "MLS";
        searchBar.style.border = "";
        searchBar.style.boxShadow = "";
        searchBarIcon.src = SearchBarIcon;
      }
    }

    function hideSearchBar() {
      if (searchBar && searchBarIcon && searchLi && HeaderLogo && Header) {
        searchBar.classList.add("slideUp");
        searchBar.classList.remove("slideDown");
        searchBar.style.width = "0px";
        searchBar.style.backgroundColor = "#2699fb";
        searchBar.style.borderRadius = "50%";
        searchBar.style.border = "none";
        searchBar.style.boxShadow = "none";
        searchBarIcon.src = SearchIcon;

        setTimeout(() => {
          if (searchBar && searchLi && Header && HeaderLogo) {
            searchBar.style.backgroundColor = "";
            searchBar.style.borderRadius = "";
            searchBar.style.display = "none";
            searchLi.style.display = "block";
            // Reseteamos la altura del Header en base a la Ruta
            Header.style.height =
              location.pathname === "/blu-world" ? "120px" : "150px";
            HeaderLogo.style.width =
              location.pathname === "/blu-world" ? "100px" : "120px";
          }
        }, 300);
      }
    }

    searchButton?.addEventListener("click", (event) => {
      event.preventDefault();
      showSearchBar();
    });

    searchLi?.addEventListener("click", (event) => {
      event.preventDefault();
      showSearchBar();
    });

    searchBarIcon?.addEventListener("click", (event) => {
      event.preventDefault();
      hideSearchBar();
    });

    return () => {
      searchButton?.removeEventListener("click", showSearchBar);
      searchLi?.removeEventListener("click", showSearchBar);
      searchBarIcon?.removeEventListener("click", hideSearchBar);
    };
  }, [location.pathname]);

  const toggleBurgerMenu = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
  };

  return (
    <header className="header glassEffect" id="Top">
      <Link to="/" className="header__logo--link">
        <div
          className="header__logo"
          id="HeaderLogo"
          style={{
            width: location.pathname === "/blu-world" ? "100px" : "120px",
          }} // Ancho Dinamico del Logo segun la Ruta
        >
          <img
            draggable="false"
            src={location.pathname === "/blu-world" ? BluWhiteLogo : BluLogo}
            alt="Blu Realty & Investments Logo"
            className="header__logo-img"
          />
        </div>
      </Link>
      <div className="burgerMenu">
        <button className="burgerMenu" onClick={toggleBurgerMenu}>
          <img src={BurgerIcon} alt="" aria-hidden />
          <p>Blu Realty & Investments</p>
        </button>
      </div>
      <nav className="header__nav">
        <ul
          className={`header__nav-ul ${
            burgerMenuOpen ? "burgerOpened" : "burgerClosed"
          }`}
        >
          <div
            className="closeBurgerMenu"
            onClick={() => setBurgerMenuOpen(false)}
          ></div>
          <img
            draggable="false"
            src={location.pathname === "/blu-world" ? BluWhiteLogo : BluLogo}
            alt="Blu Realty & Investments Logo"
            className="header__burger-img"
          />

          {location.pathname === "/blu-world" ? (
            <>
              {/* LINKS DIFERENTES EN EL HEADER PRESENTES EN BLUWORLD */}
              <li
                className="header__nav-li"
                style={{ textTransform: "uppercase", color: "white" }}
              >
                <Link
                  to="#beach-clean"
                  className="header__link"
                  style={{ color: "white", textTransform: "uppercase" }}
                >
                  Beach Clean
                </Link>
              </li>
              <li
                className="header__nav-li"
                style={{ textTransform: "uppercase", color: "white" }}
              >
                <Link
                  to="#el-dorado-discovered"
                  className="header__link"
                  style={{ color: "white", textTransform: "uppercase" }}
                >
                  El Dorado Discovered
                </Link>
              </li>
              <li
                className="header__nav-li"
                style={{ textTransform: "uppercase", color: "white" }}
              >
                <Link
                  to="#observer-academy"
                  className="header__link"
                  style={{ color: "white", textTransform: "uppercase" }}
                >
                  Observer Academy
                </Link>
              </li>
              <li
                className="header__nav-li"
                style={{ textTransform: "uppercase", color: "white" }}
              >
                <Link
                  to="#blu-cares"
                  className="header__link"
                  style={{ color: "white", textTransform: "uppercase" }}
                >
                  Blu Cares
                </Link>
              </li>
              <li className="header__nav-li">
                <button className="header__link--stayInTouch">
                  Stay in Touch
                </button>
              </li>
            </>
          ) : (
            <>
              {/* LINKS POR DEFECTO PARA OTRAS PAGINAS */}
              <li className="header__nav-li">
                <Link
                  to="/book-a-call"
                  className="header__link hvr-underline-from-center"
                >
                  Book a Call
                </Link>
              </li>
              <li className="header__nav-li">
                <Link
                  to="/buying"
                  className="header__link hvr-underline-from-center"
                >
                  Buying
                </Link>
              </li>
              <li className="header__nav-li">
                <Link
                  to="/selling"
                  className="header__link hvr-underline-from-center"
                >
                  Selling
                </Link>
              </li>
              <li className="header__nav-li">
                <Link
                  to="/management"
                  className="header__link hvr-underline-from-center"
                >
                  Management
                </Link>
              </li>
              <li className="header__nav-li">
                <Link
                  to="/market-report"
                  className="header__link hvr-underline-from-center"
                >
                  Market Report
                </Link>
              </li>
            </>
          )}

          {/* Ocultamos el SearchBar y el Boton de Login en /blu-world */}
          {location.pathname !== "/blu-world" && (
            <>
              <li
                className="header__nav-li hvr-underline-from-center userLi"
                style={{ height: "3.22rem" }}
              >
                <Link to="/login" className="header__link header__link--LogIn">
                  <img
                    draggable="false"
                    src={UserIcon}
                    alt="Log In"
                    className="userIcon"
                  />
                  Log In
                </Link>
              </li>
              <li className="header__nav-li" id="searchLi">
                <button
                  id="searchButton"
                  className="header__link hvr-underline-from-center"
                  style={{
                    paddingBottom: "1rem",
                    marginTop: "1rem",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="

header__link--Search"
                  >
                    <img
                      draggable="false"
                      src={SearchIcon}
                      alt="Search"
                      className="header__link--SearchImg"
                    />
                  </div>
                </button>
              </li>
            </>
          )}

          <span className="copyrightBurgerMenu">
            Copyright © 2024 <br /> Blu Realty & Investments Company. <br /> All
            rights reserved.
          </span>
        </ul>
        <div id="searchBar" className="header__searchBar">
          <input type="text" placeholder="MLS" />
          <img
            draggable="false"
            src={SearchBarIcon}
            alt="Search MLS"
            id="searchBarIcon"
            className="header__searchBarIcon"
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;
