import "./StayInTouch.css";

export default function StayInTouch() {
  return (
    <div className="stay-main-container">
      <div className="stay-container">
        <h1 className="stay-title">Stay in Touch</h1>
        <p className="stay-text">
          We are currently not accepting donations because we are in the process
          of organizing.
          <br />{" "}
          <span className="stay-text-bold">
            {" "}
            If you would like to stay informed, subscribe and follow us.
          </span>{" "}
        </p>
        <button className="stay-sub">SUBSCRIBE</button>
      </div>
    </div>
  );
}
