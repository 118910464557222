export default function HandSection({ handlePrint }) {
  const HandImage =
    "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FHands.svg?alt=media&token=7c73bb37-c738-4ce6-9d0f-76c2ad1b4928";
  const PrintImage =
    "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FPrintIconMAForm.svg?alt=media&token=f0208fdd-2411-4565-9f88-031decbce881";
  const SendImage =
    "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FSendIconMAFormHand.svg?alt=media&token=b1b83935-7f85-43c7-90ad-8c017cff5376";
  return (
    <div
      className="content-col HandSection"
      style={{
        position: "relative",
        height: "100%",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <label
        className="xlLabel"
        style={{
          position: "absolute",
          width: "100%",
          marginTop: "7%",
        }}
      >
        Sincerely!
      </label>
      <div className="HandContainer">
        <img src={HandImage} className="content-img" alt="HeroImage" />
        <div className="triangle-down"></div>
      </div>
      <div className="print-send-images-container" onClick={handlePrint}>
        <p
          className="xlLabel"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img src={PrintImage} className="print-send-icon" />
          Print
        </p>
        <p
          className="xlLabel"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          Send
          <img src={SendImage} className="print-send-icon" />
        </p>
      </div>
    </div>
  );
}
