import React from "react";
import "./HeroSection.css";
const bgVideo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/HomePage%2FVideo%2FbgVideo.mp4?alt=media&token=0ec00ad4-bfd6-47f3-a3a2-20312cd7d6f6";
const bgPoster =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/HomePage%2FImages%2FbgPoster.png?alt=media&token=65689c98-f8c4-465a-a6c6-2a4c27e0bdbd";

const HeroSection = () => {
  return (
    <section className="main__hero">
      <div className="hero__overlayVideo"></div>
      <video
        muted
        autoPlay
        loop
        playsInline
        poster={bgPoster}
        alt="Photo of Sunny Isles"
        className="video"
      >
        <source src={bgVideo} type="video/mp4" alt="Video of Sunny Isles" />
      </video>
      <form
        action="submit_form.php"
        className="formMlsSearcher hero__content hero__mlsSearcher"
        method="post"
      >
        <input
          type="text"
          className="input__mlsSearcher"
          placeholder="Sales, Lease, Commercial"
        />
        <input type="submit" value="Search" className="bttn_mlsSearcher" />
      </form>
      <div className="hero__signature preventSelect">
        <p>Blu Realty & Investments</p>
      </div>
    </section>
  );
};

export default HeroSection;
