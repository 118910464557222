// Form.jsx
import React from "react";
import MAPrincipalSection from "./Form/MAPrincipalSection";
import SectionTwelve from "./Form/SectionTwelve";
import PriceAndTermsSection from "./Form/PriceAndTermsSection";
import ClauseFourTotalFourEleven from "./ClauseFourTotalFourEleven";
import ContactInformation from "./Form/ContactInformation";
import MailingAddress from "./Form/MailingAddress";
import BankDeposit from "./Form/BankDeposit";
import InsuranceSection from "./Form/InsuranceSection";
import InsuranceMortgageSection from "./Form/InsuranceMortgageSection";
import HomeownersAssociationSection from "./Form/HomeownersAssociationSection";
import UtilitiesSection from "./Form/UtilitiesSection";
import FinalButtonSection from "./Form/FinalButtonSection";

const Form = ({ handleChangePart1, handleChangePart2, handleSubmit }) => {
  return (
    <form className="MAForm">
      <MAPrincipalSection onDataChange={handleChangePart1} />
      <SectionTwelve onDataChange={handleChangePart1} />
      <PriceAndTermsSection onDataChange={handleChangePart1} />
      <ClauseFourTotalFourEleven />
      <ContactInformation onDataChange={handleChangePart1} />
      <MailingAddress onDataChange={handleChangePart1} />
      <BankDeposit onDataChange={handleChangePart2} />
      <InsuranceSection
        onDataChange={handleChangePart2}
        onDataChange1={handleChangePart1}
      />
      <InsuranceMortgageSection onDataChange={handleChangePart2} />
      <HomeownersAssociationSection onDataChange={handleChangePart2} />
      <UtilitiesSection onDataChange={handleChangePart2} />
      <FinalButtonSection onSubmit={handleSubmit} />
    </form>
  );
};

export default Form;
