import React, { useState } from "react";
import PrimarySection from "./PrimarySection";
import AboutMeSection from "./AboutMeSection";
import HighlightsSection from "./HighlightsSection";
import "./BioPageAgent.css";

const BioSection = () => {
  const [activeSection, setActiveSection] = useState("primary");
  const [btnHighlightsText, setBtnHighlightsText] = useState("Highlights");
  const [btnAboutMeText, setBtnAboutMeText] = useState("About Me");

  const handleHighlightsClick = () => {
    if (btnHighlightsText === "Contact") {
      setActiveSection("primary");
      setBtnHighlightsText("Highlights");
      setBtnAboutMeText("About Me");
    } else {
      setActiveSection("highlights");
      setBtnHighlightsText("Contact");
    }
  };

  const handleAboutMeClick = () => {
    if (btnAboutMeText === "Contact") {
      setActiveSection("primary");
      setBtnHighlightsText("Highlights");
      setBtnAboutMeText("About Me");
    } else {
      setActiveSection("aboutMe");
      setBtnAboutMeText("Contact");
    }
  };

  return (
    <section className="bio-section">
      <h2 className="bio-title">I keep my clients updated</h2>
      <div className="bio-content">
        <div className="bio-content-left">
          <PrimarySection isActive={activeSection === "primary"} />
          <AboutMeSection isActive={activeSection === "aboutMe"} />
          <HighlightsSection isActive={activeSection === "highlights"} />

          <div className="bio-agent-buttons">
            <button className="bio-button" onClick={handleHighlightsClick}>
              {btnHighlightsText}
            </button>
            <button className="bio-button" onClick={handleAboutMeClick}>
              {btnAboutMeText}
            </button>
          </div>
        </div>

        <div className="bio-content-right">
          <div className="bio-agent-img"></div>
        </div>
      </div>
    </section>
  );
};

export default BioSection;
