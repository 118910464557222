import { React, useState } from "react";

export default function MAPrincipalSection({ onDataChange }) {
  const [part0Data, setPart0Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart0Data({
      ...part0Data,
      [name]: value,
    });
    onDataChange({
      ...part0Data,
      [name]: value,
    });
  };

  return (
    <div className="MAPrincipalSection">
      <h1 className="principalTitle">MANAGEMENT AGREEMENT</h1>
      <div className="MAPrincipalOwner">
        <label htmlFor="MA_Owner_Record">
          <h3 className="MAPrincipalOwner_SubTitle">
            <span className="fBold">(“Agreement”) is between </span>
          </h3>
        </label>
        <input
          type="text"
          placeholder="Owner of Record"
          name="MA_Owner_Record"
          id="MA_Owner_Record"
          onChange={handleChange}
        />
      </div>
      <h3 className="MAPrincipalOwner_SubTitle MAPrincipalOwner_SubTitle--Center">
        ("<span className="fBold">Owner</span>") and Blu Realty & Investments,
        LLC (<span className="fBold">Manager</span>)
      </h3>
      <h3>
        <span className="fBold">1. Owner</span> gives{" "}
        <span className="fBold">Manager</span> the EXCLUSIVE RIGHT TO BE BROKER
        in the sale, lease and management of the real and personal property
        (collectively “Property”) described below{" "}
        <label htmlFor="MA_Start_Date">beginning</label> the
      </h3>
      <div className="dFlex">
        <input
          type="date"
          name="MA_Start_Date"
          id="MA_Start_Date"
          onChange={handleChange}
        />
        <h3 className="noWrap">
          and <label htmlFor="MA_End_Date">terminating</label> at 11:59 p.m. on
        </h3>
        <input
          type="date"
          name="MA_End_Date"
          id="MA_End_Date"
          onChange={handleChange}
        />
      </div>
      <h3>
        Upon full execution of a contract for sale and purchase of the Property,
        all rights and obligations of this Agreement will automatically extend
        through the date of the actual closing of the sales contract. This
        Agreement shall be cancelable after one year, with or without cause, by
        either party with one hundred and eighty (180) days written notice.{" "}
        <span className="fBold">Owner</span> certificates and represents that
        he/she has the legal authority and capacity to lease the Property and
        improvements.
      </h3>
    </div>
  );
}
