import React from "react";
import "./BluWorldHero.css";
const GoToBottomArrow =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FGoToBottomArrow.svg?alt=media&token=ba477e23-57f2-4a50-9171-bfa9d6ded2a2";
const BluWorldLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FBluWorldLogoWhite.svg?alt=media&token=90473d63-7f50-4846-a60d-dee4000c772a";
const BeachCleanImg =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_25.jpg?alt=media&token=ab812d77-c1f0-4b76-8ecc-8a62b90f955a";
const ObserverAcademyImg =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_May_10.jpg?alt=media&token=aa56b07f-7e8a-4d9b-97b7-5cd09952b04a";
const BluCaresImg =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_34.jpg?alt=media&token=f4ff7ad6-83ed-4330-83b8-00e397b4f017";
const ElDoradoImg =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBLU_Img_Mar_20.jpg?alt=media&token=d032381e-5ec7-41f7-baed-798f88bb9022";
const BluHeroImg =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FBluWorld_Waves.svg?alt=media&token=6be39f9b-24f8-4af8-923e-626d7a498874"

export default function BluWorldHero() {
  return (
    <div className="BluWolrdHeroImg">
      <img src={BluHeroImg} className="HeroImage"/>

      <div className="BluWorldHero">
        <img src={BluWorldLogo} alt="BluWorld" className="BluWorld_Logo" />
        <h1 className="BluWorldHeroTitle">
          Protecting our <span className="fBold">world</span> starts with{" "}
          <span className="fBold">each of us.</span>
        </h1>
        <div className="BluWorld_Cards">
          <div className="BluWorld_Card BluWorld_Card--BeachClean">
            <img
              src={BeachCleanImg}
              alt="Beach Clean"
              className="BluWorld_CardImage"
            />
            <div className="BluWorld_CardContent">
              <h2 className="BluWorld_CardTitle">Beach Clean</h2>
            </div>
          </div>
          <div className="BluWorld_Card BluWorld_Card--ElDorado">
            <img
              src={ElDoradoImg}
              alt="El Dorado Discovered"
              className="BluWorld_CardImage"
            />
            <div className="BluWorld_CardContent">
              <h2 className="BluWorld_CardTitle">El Dorado Discovered</h2>
            </div>
          </div>
          <div className="BluWorld_Card BluWorld_Card--ObserverAcademy">
            <img
              src={ObserverAcademyImg}
              alt="Observer Academy"
              className="BluWorld_CardImage"
            />
            <div className="BluWorld_CardContent">
              <h2 className="BluWorld_CardTitle">Observer Academy</h2>
            </div>
          </div>
          <div className="BluWorld_Card BluWorld_Card--BluCares">
            <img
              src={BluCaresImg}
              alt="Blu Cares"
              className="BluWorld_CardImage"
            />
            <div className="BluWorld_CardContent">
              <h2 className="BluWorld_CardTitle">Blu Cares</h2>
            </div>
          </div>
        </div>
        <img
          src={GoToBottomArrow}
          className="GoToBottomArrow"
          alt=""
          aria-hidden
        />
      </div>
    </div>
  );
}
