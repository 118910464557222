import React, { useState } from "react";
import "./ServicesSection.css";
const SunnyIsles2 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/HomePage%2FImages%2FSunnyIsles2.jpeg?alt=media&token=98328710-ebb6-4e30-b9cd-a0b844d34921";
const ApartBedroom =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/HomePage%2FImages%2FApartBedroom.jpeg?alt=media&token=5926fabc-7d19-40ff-89aa-ed80c5d5fe1a";
const SellingIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FSelling.svg?alt=media&token=eddafb38-3e61-4ec1-b406-1af64fe477e9";
const AcquisitionsIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitions.svg?alt=media&token=4f79e12b-9faa-4659-8914-1f80300683b5";
const DownArrow =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FdownArrow.svg?alt=media&token=b12fab39-a230-4ba7-9e71-33e7bc0b76b8";
const CloseIcon =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FcloseIcon.svg?alt=media&token=2221cc57-3e10-4b1a-8571-b430481a2ddd";
const AcquisitionsIcon1 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsIcon1.svg?alt=media&token=911a70a0-97ee-4395-8571-1cdbee26bf90";
const AcquisitionsIcon2 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsIcon2.svg?alt=media&token=95347936-088b-4a04-a096-b8db61f0edba";
const AcquisitionsIcon3 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsIcon3.svg?alt=media&token=f693d4fc-42c3-4abd-baee-1e6f702df179";
const AcquisitionsIcon4 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsIcon4.svg?alt=media&token=a998eb5d-f6cf-4cfd-9ae2-ec4884f06aac";
const AcquisitionsIcon5 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsIcon5.svg?alt=media&token=4efde0d3-6e3d-42bc-84c3-5ad13bc47b31";
const AcquisitionsIcon6 =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FAcquisitionsIcon6.svg?alt=media&token=af08ccb3-0a88-409d-9ad4-3edca940af03";

const SellingSection = ({ togglePopup }) => (
  <div
    className="main__service main__serviceSelling"
    id="openSelling"
    style={{ backgroundImage: `url(${SunnyIsles2})` }}
    onClick={() => togglePopup("selling")}
  >
    <div className="main__serviceSelling--Overlay"></div>
    <div className="mainService__icon">
      <img src={SellingIcon} alt="Selling Services" draggable="false" />
    </div>
    <h3 className="mainService__title">Selling</h3>
    <p className="mainService__text">
      As the world's first <strong>AI-powered</strong> real estate company, we
      have created a platform that frees our sales team to build relationships,
      while our <strong>expert in-house maintenance technicians</strong> resolve
      property needs.
    </p>
    <p className="quoteSellingAcquisitions">Smooth transaction!</p>
  </div>
);

const AcquisitionsSection = ({ togglePopup }) => (
  <div
    className="main__service main__serviceAcquisitions"
    id="openAcquisitions"
    style={{ backgroundImage: `url(${ApartBedroom})` }}
    onClick={() => togglePopup("acquisitions")}
  >
    <div className="main__serviceAcquisitions--Overlay"></div>
    <div className="mainService__icon">
      <img
        src={AcquisitionsIcon}
        alt="Acquisitions Services"
        draggable="false"
      />
    </div>
    <h3 className="mainService__title">Acquisitions</h3>
    <p className="mainService__text">
      Specializing in Miami, the Blu buying experience is simple. <br />
      Grow your portfolio without management worries.
    </p>
    <p className="quoteSellingAcquisitions">
      Life is sweeter when stress is minimal!
    </p>
  </div>
);

const ServicesSection = () => {
  // Estado para controlar la visibilidad de los popups
  const [popupVisible, setPopupVisible] = useState({
    selling: false,
    acquisitions: false,
  });

  // Función para abrir/cerrar popups
  const togglePopup = (popupType) => {
    setPopupVisible((prevState) => ({
      ...prevState,
      [popupType]: !prevState[popupType],
    }));
  };

  return (
    <section className="main__services">
      <SellingSection togglePopup={togglePopup} />
      <AcquisitionsSection togglePopup={togglePopup} />

      {/* Selling PopUp */}
      {popupVisible.selling && (
        <div className="sellingServicesPopUp popup">
          <div className="sellingServicesContent">
            <div className="sellingServices__top">
              <h3 className="sellingServicesPopUp__title">Selling Services</h3>
              <p className="sellingServicesPopUp__text">
                Skilled negotiators. Proven marketing strategies: exposure in
                100+ real estate websites, social media & direct mail generate a
                frenzy of active buyers and deliver <strong>Top Dollar</strong>{" "}
                results.
              </p>
            </div>
            <div className="closePopUpContainer">
              <div className="closePopUp" style={{ cursor: "default" }}>
                <img
                  className="downArrow"
                  src={DownArrow}
                  alt="Down Arrow of Selling Services PopUp"
                />
              </div>
            </div>
            <form className="sellingServicesForm" method="post">
              <div className="sellingServices__topInputs">
                <input
                  type="text"
                  name="sellingServicesName"
                  placeholder="Name"
                />
                <input
                  type="email"
                  name="sellingServicesEmail"
                  placeholder="E-mail"
                />
                <input
                  type="tel"
                  name="sellingServicesPhone"
                  placeholder="Phone"
                />
              </div>
              <input
                type="text"
                name="sellingServicesAddress"
                placeholder="Complete Address, Zip Code"
                style={{ width: "100%" }}
              />
              <p className="sellingServicesText">
                Are there any additions or remodels that add value?
              </p>
              <input
                type="submit"
                value="Submit"
                className="sellingServicesSubmit"
              />
            </form>
            <div
              className="closePopUpContainer"
              onClick={() => togglePopup("selling")}
            >
              <div className="closePopUp">
                <img src={CloseIcon} alt="Close Selling Services PopUp" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Acquisitions PopUp */}
      {popupVisible.acquisitions && (
        <div className="AcquisitionsPopUp popup">
          <div className="AcquisitionsContent">
            <div className="Acquisitions__top">
              <h3 className="AcquisitionsPopUp__title">Our Clients Enjoy</h3>
            </div>
            <div className="AcquisitionsCircleIcons">
              <div className="AcquisitionsCircleIconsColumnOne">
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon1}
                      alt="Market and ROI Analysis"
                      className="AcquisitionsIconImg1"
                      draggable="false"
                    />
                  </div>
                  <p>Market and ROI Analysis</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon2}
                      alt="Geographical, Demographical & Censual Reports"
                      className="AcquisitionsIconImg2"
                      draggable="false"
                    />
                  </div>
                  <p>Geographical, Demographical & Censual Reports</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon3}
                      alt="Foreclosure Acquisitions"
                      className="AcquisitionsIconImg3"
                      draggable="false"
                    />
                  </div>
                  <p>Foreclosure Acquisitions</p>
                </div>
              </div>
              <div className="AcquisitionsCircleIconsColumnTwo">
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon4}
                      alt="Asset Preservation"
                      className="AcquisitionsIconImg4"
                      draggable="false"
                    />
                  </div>
                  <p>Asset Preservation</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon5}
                      alt="Clear Budgets & Insightful Reports"
                      className="AcquisitionsIconImg5"
                      draggable="false"
                    />
                  </div>
                  <p>Clear Budgets & Insightful Reports</p>
                </div>
                <div className="AcquisitionsIcon">
                  <div className="AcquisitionsCircleIconContainer">
                    <img
                      src={AcquisitionsIcon6}
                      alt="Resident Retention"
                      className="AcquisitionsIconImg6"
                      draggable="false"
                    />
                  </div>
                  <p>Resident Retention</p>
                </div>
              </div>
            </div>
            <div className="AcquisitionsImg">
              <div className="Acquisitions__info">
                <h3>Local Insurers & Tax Attorneys</h3>
                <ul>
                  <li>Liability</li>
                  <li>Ordinance Compliance & Lien Reduction</li>
                  <li>Domestic and Foreign Businesses Formations</li>
                  <li>Trusts & Estate Planning</li>
                </ul>
              </div>
            </div>
          </div>
          <form className="AcquisitionsForm" method="post">
            <p className="AcquisitionsPopUp__text">
              By focusing on the detailed needs of real estate investment, we
              cultivate long-term & mutually successful relationships.
            </p>
            <div className="Acquisitions__topInputs">
              <input
                type="text"
                name="AcquisitionsName"
                id="AcquisitionsName"
                placeholder="Name"
              />
              <input
                type="email"
                name="AcquisitionsEmail"
                id="AcquisitionsEmail"
                placeholder="E-mail"
              />
              <input
                type="tel"
                name="AcquisitionsPhone"
                id="AcquisitionsPhone"
                placeholder="Phone"
              />
            </div>
            <input
              type="text"
              name="AcquisitionsAddress"
              id="AcquisitionsAddress"
              placeholder="Complete Address, Zip Code"
              style={{ width: "100%" }}
            />
            <p className="AcquisitionsText">
              Specific interest or requirements
            </p>
            <input
              type="submit"
              value="Submit"
              className="AcquisitionsSubmit"
            />
          </form>
          <div
            className="closePopUpContainer"
            onClick={() => togglePopup("acquisitions")}
          >
            <div className="closePopUp">
              <img src={CloseIcon} alt="Close Acquisitions Services PopUp" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ServicesSection;
