import { React } from "react";
import style from "./ObserverPopUp.module.css";
import CloseButton from "../CloseButton/CloseButton";
const football =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2Ffootball.svg?alt=media&token=f3b20e5f-d4d5-41de-ae99-046fa6cac0e5";

function ObserverPopUp({ setPopUp }) {
  return (
    <div className={style.popUpContainer}>
      <h4 className={style.title}>For Example:</h4>
      <p className={style.content}>
        A soccer athlete struggling with <strong>physics</strong> concepts can
        be taught these principles through the lens of soccer, making the{" "}
        <strong>learning process more engaging and effective.</strong>
      </p>
      <img src={football} alt="Football field" />
      <ol className={style.olContainer}>
        <div>
          <li className={style.points}>
            <p className={style.formula}>v = d/t</p>
            <p className={style.description}>
              — (Speed (in meters per second, m/s)).
            </p>
          </li>
          <li className={style.points}>
            <p className={style.formula}>
              y = x tan (?) - (g x² ) / (2 v² cos² (?))
            </p>
            <p className={style.description}>
              — Ball Trajectory (Parabolic Movement).
            </p>
          </li>
          <li className={style.points}>
            <p className={style.formula}>Ek = 1 / 2 mv²</p>
            <p className={style.description}>— Kinetic Energy of the Ball.</p>
          </li>
        </div>
        <div>
          <li className={style.points}>
            <p className={style.formula}>F = m * a</p>
            <p className={style.description}>— Ball Striking Force.</p>
          </li>
          <li className={style.points}>
            <p className={style.formula}>a = Δ v / Δt</p>
            <p className={style.description}>— Player Acceleration</p>
          </li>
        </div>
      </ol>
      <CloseButton setPopUp={setPopUp} />
    </div>
  );
}

export default ObserverPopUp;
