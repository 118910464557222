import { React } from "react";
import style from "./ObserverBody.module.css";

function ObserverBody({ popUp, setPopUp }) {
  const handlePopUp = () => {
    setPopUp(!popUp);
  };

  return (
    <div className={style.containerBody}>
      <h4 className={style.meetContainer}>MEET THE</h4>
      <h4 className={style.title}>Observer Academy</h4>
      <p className={style.paragraph}>
        Our mission is to empower the next generation of leaders through an{" "}
        <button className={style.activePopUp} onClick={handlePopUp}>
          {" "}
          AI infused learning{" "}
        </button>{" "}
        from elementary to highschool. We provide boarding facilities, meals and
        rigurous curriculum that emphasizes academic excellence. Our approach
        includes advanced technology training, communication skills development,
        and personal growth inspired by visonary American leaders.
        <br />
        <br />
        Situated in a peaceful countryside environment, we support orphaned and
        underpriviliged children, equiping them with resilience, compassion and
        a commitment to making a positive impact in their communities.
      </p>
    </div>
  );
}

export default ObserverBody;
