import { React, useState } from "react";

const BankDeposit = ({ onDataChange }) => {
  const [part5Data, setPart5Data] = useState({
    Bank_Account_Owner_Name: "",
    Bank_Name: "",
    Account_Bank_Number: "",
    Bank_AccountType: "",
    Bank_Instructions: "",
    Owner_authorizes_Manager_to_make_payments_on_Owner: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedData = { ...part5Data };

    if (type === "checkbox") {
      if (checked) {
        updatedData.Owner_authorizes_Manager_to_make_payments_on_Owner.push(
          value
        );
      } else {
        updatedData.Owner_authorizes_Manager_to_make_payments_on_Owner =
          updatedData.Owner_authorizes_Manager_to_make_payments_on_Owner.filter(
            (item) => item !== value
          );
      }
    } else {
      updatedData[name] = value;
    }

    setPart5Data(updatedData);
    onDataChange(updatedData);
    console.log(updatedData);
  };

  return (
    <section className="bankDeposit__Section">
      <h3 className="section__Title weightBold">
        Please provide your bank account to receive your rent proceeds and pay
        for repairs when needed.
      </h3>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label
          htmlFor="Bank_Account_Owner_Name"
          className="sectionTwelve__Label noWrap"
        >
          Bank Account Holder/Owner Name:
        </label>
        <input
          type="text"
          name="Bank_Account_Owner_Name"
          className="sectionTwelve__Input"
          id="bankDeposit__OwnerName"
          value={part5Data.Bank_Account_Owner_Name}
          onChange={handleChange}
        />
      </div>

      <div className="bankDeposit__InputGrid">
        <input
          type="text"
          placeholder="Bank Name"
          name="Bank_Name"
          id="bankDeposit_BankInput"
          value={part5Data.Bank_Name}
          onChange={handleChange}
        />
        <input
          className="iconPencil"
          type="text"
          placeholder="Bank Account"
          name="Account_Bank_Number"
          id="bankDeposit_AccountInput"
          value={part5Data.Account_Bank_Number}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Savings or Checkings"
          name="Bank_AccountType"
          id="Bank_AccountType"
          value={part5Data.Bank_AccountType}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Special Instructions"
          name="Bank_Instructions"
          id="Bank_Instructions"
          value={part5Data.Bank_Instructions}
          onChange={handleChange}
        />
      </div>

      <h3 className="section__Title weightBold marginTopTwo">
        Owner authorizes Manager to make payments on Owner’s behalf: (check all
        that apply)
      </h3>

      <div className="dGrid gridCheckbox spaceBet">
        {[
          { name: "Legal Fees", id: "bankDeposit_authorizesLegalFees" },
          { name: "Property Taxes", id: "bankDeposit_authorizesPropertyTaxes" },
          { name: "Permit Fees", id: "bankDeposit_authorizesPermitFees" },
          { name: "Utilities", id: "bankDeposit_authorizesUtilities" },
          { name: "HOA", id: "bankDeposit_authorizesHOA" },
        ].map((item) => (
          <div className="checkboxContainer" key={item.id}>
            <label className="checkbox-label" htmlFor={item.id}>
              {item.name}
              <input
                type="checkbox"
                name="Owner_authorizes_Manager_to_make_payments_on_Owner"
                id={item.id}
                value={item.name}
                checked={part5Data.Owner_authorizes_Manager_to_make_payments_on_Owner.includes(
                  item.name
                )}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        ))}
      </div>
    </section>
  );
};

export default BankDeposit;
