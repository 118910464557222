const HighlightsSection = ({ isActive }) => (
  <div
    className={`bio-content__highlights ${isActive ? "active" : "hidden"}`}
    style={{
      opacity: isActive ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    }}
  >
    <h2 id="bioContentTitle" className="bio-content-title">
      Highlights
    </h2>
    <div className="bio-content__contact__container">
      <div className="bio-agent-hightlightsContent">
        <div className="bio-agent-hightlights__item">
          <span className="BioDiamondIcon mRight1Rem"></span>
          <span className="DMSerif mRight1Rem">20</span>
          <span className="MontserratLight">
            Years in <br />
            <strong>Real Estate</strong>
          </span>
        </div>
        <div className="bio-agent-hightlights__item">
          <span className="BioDiamondIcon mRight1Rem"></span>
          <div className="dFColumn">
            <span className="DMSerif mRight1Rem">Driven</span>
            <span className="MontserratLight affiliateGrowth">
              In Supporting Affiliate Growth
            </span>
          </div>
        </div>
        <div className="bio-agent-hightlights__item">
          <span className="BioDiamondIcon mRight1Rem"></span>
          <div className="dFColumn">
            <span className="DMSerif mRight1Rem">Focused</span>
            <span className="MontserratLight">On Delivering Value</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HighlightsSection;
