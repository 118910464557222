import { React, useState } from "react";

const PriceAndTermsSection = ({ onDataChange }) => {
  const [part2Data, setPart2Data] = useState({
    Financing_Term_Cash: false,
    Financing_Term_Conventional: false,
    Financing_Term_VA_FHA: false,
    Price: "",
    Lease: "",
  });

  const handleChange = (e) => {
    const { name, checked, type, value } = e.target;

    // Actualizamos part2Data para reflejar los cambios en inputs y checkboxes
    const updatedValue = type === "checkbox" ? checked : value;
    const updatedPart2Data = {
      ...part2Data,
      [name]: updatedValue,
    };

    setPart2Data(updatedPart2Data);

    // Si es un input de texto, actualizamos directamente
    if (type !== "checkbox") {
      onDataChange(updatedPart2Data);
    } else {
      // Para los checkboxes, construimos un array con los nombres de los términos de financiación seleccionados
      const financingTermsSelected = [
        "Financing_Term_Cash",
        "Financing_Term_Conventional",
        "Financing_Term_VA_FHA",
      ]
        .filter((term) => updatedPart2Data[term]) // Filtramos solo los que están checked
        .map((term) => term.replace("Financing_Term_", "")); // Opcional: limpiar los nombres si es necesario

      onDataChange({
        ...updatedPart2Data,
        Financing_Terms: financingTermsSelected.join(", "),
        Price: updatedPart2Data.Price,
        Lease: updatedPart2Data.Lease,
      });
      console.log(financingTermsSelected);
    }
  };

  return (
    <section className="priceAndTerms__Section">
      <h2 className="section__Title lineHeight">
        <span>Price and Terms:</span> The property is offered for sale and/or
        lease on the following terms or on other terms acceptable to Seller:
      </h2>

      <label htmlFor="Price" className="sectionTwelve__DropdownLabel">
        (a) Price:
        <input
          type="text"
          placeholder="$000.000.000"
          className="tenantInfo__Input priceAndTerms__Inputs"
          name="Price"
          id="Price"
          value={part2Data.Price}
          onChange={handleChange}
        />
      </label>

      <label htmlFor="Lease" className="sectionTwelve__DropdownLabel">
        Lease:
        <input
          type="text"
          placeholder="$000.000.000"
          className="tenantInfo__Input priceAndTerms__Inputs"
          name="Lease"
          id="Lease"
          value={part2Data.Lease}
          onChange={handleChange}
        />
      </label>

      <div className="propertyOccupancy">
        <h2 className="propertyType__Title financingTerms__Title">
          (b) Financing Terms:
        </h2>

        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="Financing_Term_Cash">
            Cash
            <input
              type="checkbox"
              id="Financing_Term_Cash"
              name="Financing_Term_Cash"
              checked={part2Data.Financing_Term_Cash}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="checkboxContainer">
          <label
            className="checkbox-label"
            htmlFor="Financing_Term_Conventional"
          >
            Conventional
            <input
              type="checkbox"
              id="Financing_Term_Conventional"
              name="Financing_Term_Conventional"
              checked={part2Data.Financing_Term_Conventional}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="Financing_Term_VA_FHA">
            VA/FHA
            <input
              type="checkbox"
              id="Financing_Term_VA_FHA"
              name="Financing_Term_VA_FHA"
              checked={part2Data.Financing_Term_VA_FHA}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>

      <h3 className="copyLatestLeaseAgreements ownerExpenses lineHeight">
        (c) <span>Owner</span> Expenses: <span>Owner</span> will pay mortgage
        discount or other closing costs not to exceed 3% of the purchase price
        and any other expenses <span>Owner</span> agrees to pay in connection
        with a transaction.
      </h3>
    </section>
  );
};

export default PriceAndTermsSection;
