import { React, useState } from "react";

const MailingAddress = ({ onDataChange }) => {
  const [part4Data, setPart4Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart4Data({
      ...part4Data,
      [name]: value,
    });
    onDataChange({
      ...part4Data,
      [name]: value,
    });
  };

  return (
    <section className="mailingAddress__Section">
      <h3 className="copyLatestLeaseAgreements lineHeight">
        <span>Mailing Address</span> is where all local governing bodies will
        mail official documents such as:
        <br />
        violations or fines.
      </h3>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label
          htmlFor="Mailing_Address"
          className="sectionTwelve__Label weightBold"
        >
          Address:
        </label>
        <input
          type="text"
          name="Mailing_Address"
          className="sectionTwelve__Input"
          id="mailingInformation_Address"
          onChange={handleChange}
        />
      </div>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label htmlFor="Mailing_City_Address" className="sectionTwelve__Label">
          City:
        </label>
        <input
          type="text"
          name="Mailing_City_Address"
          className="sectionTwelve__Input"
          id="mailingInformation_City"
          onChange={handleChange}
        />
        <label
          htmlFor="Mailing_State_Address"
          className="sectionTwelve__Label marginLeft"
        >
          State:
        </label>
        <input
          type="text"
          name="Mailing_State_Address"
          className="sectionTwelve__Input"
          id="mailingInformation_State"
          onChange={handleChange}
        />
        <label
          htmlFor="Mailing_Zip_Code_Address"
          className="sectionTwelve__Label marginLeft"
        >
          ZipCode:
        </label>
        <input
          type="text"
          name="Mailing_Zip_Code_Address"
          className="sectionTwelve__Input"
          id="mailingInformation_ZipCode"
          onChange={handleChange}
        />
      </div>

      <div className="checkboxContainer marginTopTwo">
        <label
          className="checkbox-label lineHeight"
          htmlFor="Mailing_AssociationCityCounty"
        >
          Check to confirm you would like County/City and the homeowner's
          association mailing address changed to the address entered here.
          <input
            type="checkbox"
            name="Mailing_AssociationCityCounty"
            id="Mailing_AssociationCityCounty"
            value="Yes"
            onChange={handleChange}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    </section>
  );
};

export default MailingAddress;
