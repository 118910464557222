import React from "react";

export default function ClauseFourTotalFourEleven() {
  const ResponseTimeLine = "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/MAForm%2FResponseTimeLine.svg?alt=media&token=93ec5f86-da56-4383-8648-ff70785b6fef";
  return (
    <section className="clauseContainer">
      <div className="clauseFour">
        <h3 className="clauseContent">
          <span className="fBold">4. MANAGER OBLIGATIONS AND AUTHORITY:</span>{" "}
          Manager will use due diligence to manage and operate Property in
          accordance with this Agreement.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(A)</span> Owner authorizes{" "}
          <span className="fBold">Manager</span> to:
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(i)</span> Advertise the Property as Manager
          deems advisable including advertising the Property on the Internet.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(ii)</span> Complete and sign the lead-based
          paint/hazards certification on <span className="fBold">Owner’s</span>{" "}
          behalf (for Properties built before 1978).
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(iii)</span> Manage tenant relations,
          including negotiating renewals of existing leases; collecting, holding
          and disbursing rents and other amounts due or to become due to{" "}
          <span className="fBold">Owner</span>; enter into a lease/contract to
          lease on <span className="fBold">Owner’s</span> behalf (
          <span className="fBold">Owner</span> must execute special power of
          Attorney); handling tenant requests and negotiations; terminating
          tenancies and signing and serving appropriate notices on behalf of{" "}
          <span className="fBold">Owner</span>; initiating and prosecuting
          eviction and damages action on behalf on{" "}
          <span className="fBold">Owner</span>; and procuring legal counsel when
          necessary to protect <span className="fBold">Owner’s</span> interests
          and rights in connection with the Property.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(4)</span> All collected late fees will be
          split 50% / 50% between <span className="fBold">Owner</span> and
          <span className="fBold"> Manager</span>. All rents are due on the 1st
          of the month; tenants have until the 5th of the month to pay rent.
          <span className="fBold"> Owner</span> can expect to receive rental
          proceeds by the 25th to the end of the month to allow time for
          collection and repair invoices to be processed and paid. In the event
          that a tenant does not pay rent by the 8th of the month, the
          collection process will commence immediately. Tenants that have not
          paid rent by the 12th will receive a 3-day notice.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(5)</span> During vacancy,{" "}
          <span className="fBold">Manager</span> must 1. Bi-weekly evaluation
          for the property 2. Maintain/pay utility services 3. Maintain key in
          lockbox 4. Track expenses and provide an accurate income/expense
          report. Abated monthly management fee, for vacant period, to 5% (of
          last rental amount).
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(B)</span> Maintain accurate records of
          receipts, expenses and accruals to{" "}
          <span className="fBold">Owner</span> in connection with managing the
          Property. <span className="fBold">Manager</span> will render to{" "}
          <span className="fBold">Owner</span>
          itemized financial statement at the end of the fiscal year and will
          promptly remit to <span className="fBold">Owner</span> the balance of
          receipts less disbursements and accruals for future expenses. All
          fInancial statements will be posted online via the{" "}
          <span className="fBold">Owner</span> portal. The primary email address
          provided in Section 12 (below) will be used as your portal login
          username.
        </h3>
        <h3 className="clauseContent">
          Upon execution of this management agreement,{" "}
          <span className="fBold">Owner</span> will receive an email granting
          access to the owner online portal.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(C) Property maintenance: Owner</span>{" "}
          understands that Florida law requires licensed professionals in the
          construction trades to perform relevant repairs on the rental
          properties unless the repairs can be made for under $1,000.00 and are
          not of a life/safety concern. Additionally,{" "}
          <span className="fBold">Owner</span> understands that when{" "}
          <span className="fBold">Manager</span> acts as the{" "}
          <span className="fBold">Owner’s</span> agent, Florida law provides the{" "}
          <span className="fBold">Manager</span> may contract for repairs,
          maintenance, remodeling or improvements of the Property with a
          certified or registered contractor when labor and materials together
          do no exceed $5,000.00. Subject to these limitations set by law,{" "}
          <span className="fBold">Owner</span> authorizes{" "}
          <span className="fBold">Manager</span> to:
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(i)</span> Enter into contracts on{" "}
          <span className="fBold">Owner’s</span>
          behalf for utilities, public services, maintenance, repairs and other
          services as Manager deems advisable.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(ii)</span> Provide repair services from
          Manager’s in-house technicians and{" "}
          <span className="fBold">Manager’s</span> partnered contractors.{" "}
          <span className="fBold">Manager</span> will not solicit bids from
          non-partnered contractors on behalf of{" "}
          <span className="fBold">Owner</span> nor will{" "}
          <span className="fBold">Manager</span> be responsible for any damages
          or liability arising from negligence of any contractor.{" "}
          <span className="fBold">Manager</span> supervises all work and repairs
          performed by the <span className="fBold">Manager’s</span> in-house
          technicians at no additional cost. Work supervision performed by
          non-partnered and <span className="fBold">Manager’s</span> partnered
          contractors, incur the hourly rate set fort in Section #8 below.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(iii)</span> Maintain and repair interior,
          exterior and landscaping of Property (if applicable), including making
          periodic evaluations; purchasing supplies; and supervising
          alterations, modernization and redecoration of Property.{" "}
          <span className="fBold">Manager</span> will obtain prior approval of{" "}
          <span className="fBold">Owner</span> for any item or each service in
          excess of $5,000.00, except for monthly or recurring expenses and
          emergency repairs which in <span className="fBold">Manager’s</span>{" "}
          opinion are necessary to prevent the Property from becoming
          uninhabitable or damaged, to avoid suspension of services required to
          be provided by law or lease, or to avoid penalties or fines to be
          imposed by governmental entity. Service request type and{" "}
          <span className="fBold">Manager’s</span> response time are detailed in
          the chart below:
        </h3>
        <div className="clauseImgs">
          <img src={ResponseTimeLine} alt="" aria-hidden />
        </div>
        <h3 className="clauseContent">
          <span className="fBold">(D) Manager</span> to perform yearly
          evaluation prior to summer months. HVAC system is inspected to assure
          system is prepared for the summer months when it is used the most. A
          detailed report is presented to <span className="fBold">Owner</span>{" "}
          detailing the condition of the HVAC system as well as other inspected
          areas of the Property. Additional services are estimated in the
          “Preventative Care Services” section below and billed separately at
          the time of service. <span className="fBold">Owner</span> approves
          that <span className="fBold">Manager</span> will: 1. Perform HVAC
          drain-line cleaning services and 2. Change HVAC filter if necessary
          during this evaluation period. HVAC preparation services are not
          performed and billed if <span className="fBold">Owner</span> has a
          yearly HVAC maintenance contract with a licensed professional,{" "}
          <span className="fBold">Owner</span> to provided said maintenance
          contract information in Section 12, or if a move-in occurred in March
          or April. Furthermore, <span className="fBold">Owner</span> authorizes{" "}
          <span className="fBold">Manager</span> to perform general maintenance
          repairs up to $1,000.00 during this evaluation. Repairs above that
          dollar amount will require <span className="fBold">Owner’s</span>{" "}
          approval. Repairs deemed tenants’ responsibility may be collected
          during the lease term or deducted from security deposit after tenant
          has moved-out. This service is performed per unit and billed
          accordingly.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(E)</span> If the homeowners association does
          not service Rodent Bait Stations,{" "}
          <span className="fBold">Manager</span> will purchase, install and
          service Rodent Bait Stations bimonthly
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">
            (F) Forward owner rents proceeds: Manager{" "}
          </span>
          will forward all payments to <span className="fBold">Owner</span> via:
          Direct Bank Wire/Transfer (wired information must provided in Section
          below)
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(G)</span> Florida Tenancy laws, F.S. Chap
          83.49, requires tenant’s security deposit to be held in a separate
          Florida banking institution for the benefit of the tenant or a surety
          bond must be posted. Should <span className="fBold">Owner</span>{" "}
          decide to hold tenant’s security deposit,{" "}
          <span className="fBold">Owner</span> hereby indemnifies,
          holds-harmless, releases <span className="fBold">Manager</span> from
          any and all liability arising from tenant’s security deposit and/or
          damaged. <span className="fBold">Manager</span> is not an attorney at
          law and <span className="fBold">Owner</span> should consult legal
          consul for interpretation of the law.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(H)</span> In emergency circumstances and in
          order to maintain reliable and trust wording relationships with local
          vendors or service providers, <span className="fBold">Manager</span>{" "}
          must provide venders or service providers prompt payment. To
          accomplish this, <span className="fBold">Manager</span> must maintain{" "}
          <span className="fBold">$3,000.00</span> LSDTOKEN of{" "}
          <span className="fBold">Owner’s</span> proceeds in escrow during the
          life of this agreement. At termination of this agreement,{" "}
          <span className="fBold">Manager</span> will return any balance in
          escrow.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(I)</span> During vacancy of the property,{" "}
          <span className="fBold">Manager</span> will advertise the Property as
          Broker deems advisable including advertising the Property on the
          Internet
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(J)</span> During the 1st year{" "}
          <span className="fBold">Manager</span> will present a proposed monthly
          and year budget. Sequential years,{" "}
          <span className="fBold">Manager</span> will present proposed vs.
          actual expense budget and advised <span className="fBold">Owner</span>{" "}
          to withholding budgeted amount. Budgeting prevents the inability to
          resolve unexpected major repairs
        </h3>
      </div>
      <div className="clauseFive">
        <h3 className="clauseContent">
          <span className="fBold">5. OWNER OBLIGATIONS:</span> In consideration
          of the obligations of <span className="fBold">Manager, Owner</span>{" "}
          agrees:
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(A)</span> To cooperate with{" "}
          <span className="fBold">Manager</span> in carrying out the purpose of
          this Agreement.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(B)</span> To deliver all expenses incurred
          that are directly paid by <span className="fBold">Owner</span>.{" "}
          <span className="fBold">Manager</span> will not be able to provide
          adequate financial reports if <span className="fBold">Owner</span>{" "}
          fails to provide expenses.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(C)</span> To provide complete and accurate
          information to <span className="fBold">Manager</span> including
          disclosing all known facts that materially affect the value of the
          Property. If the Property was built in 1977 or earlier,{" "}
          <span className="fBold">Owner</span> will provide{" "}
          <span className="fBold">Manager</span> with all information{" "}
          <span className="fBold">Owner</span> knows about lead-based paint and
          lead-based paint hazards in the Property and with all available
          documents pertaining to such paint and hazards, as required by federal
          law. <span className="fBold">Owner</span> understands that the law
          requires the provision of this information to{" "}
          <span className="fBold">Manager</span> and to prospective tenants
          before the tenants become obligated to lease the Property.{" "}
          <span className="fBold">Owner</span> acknowledges that{" "}
          <span className="fBold">Manager</span> will rely on{" "}
          <span className="fBold">Owner’s</span> representations regarding the
          Property with dealing with prospective tenants.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(D)</span> To carry, at{" "}
          <span className="fBold">Owner’s</span> sole expense, liability and
          property damage insurance adequate to protect the interes of{" "}
          <span className="fBold">Owner</span> and Manager. Said insurance will
          name both <span className="fBold">Manager</span> and{" "}
          <span className="fBold">Owner</span> as insured parties, and shall
          indemnify, protect, defend and hold harmless provision of subparagraph
          5(h). <span className="fBold">Manager</span> will not be liable for
          any error of judgment or mistake of law or fact or for any loss caused
          by <span className="fBold">Manager’s</span> negligence, except when
          the loss is caused by <span className="fBold">Manager’s</span> willful
          misconduct. This obligation will survive the termination of the
          agreement. <span className="fBold">Owner</span> will carry insurance
          as follows:
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(i)</span> Perils of fire, lightning wind,
          hail, explosion, smoke, riot, aircraft, vehicles, vandalism, and
          burglary on the contents of the Property in the amount of $500,000.00
          or higher.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(ii)</span> “At risk” protection on the
          buildings in the amount of current total replacement cost, and on
          rental income in the amount of current lease agreement.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(iii)</span> Liability for personal injury and
          property damage in the amount of $500,000.00 minimum in the event of
          any litigation, arbitration or dispute involving an alleged violation
          by Manager or Owner, a prior Owner or a prior Manager, or any of them
          together or separately, of any law pertaining to fair employment, fair
          credit reporting, environmental protection, rent control, taxes,
          wrongful eviction, habitability of premises, fair housing, or
          discrimination of any kind, including, but not limited to, any law
          prohibiting or making illegal arbitrary discrimination on the basis of
          race, sex, creed, color, family status religion, national origin, or
          mental or physical handicap. Owner shall pay all expenses incurred by
          Manager, including, but not limited to, reasonable attorneys’ fees and
          Manager’s costs and time, and any liability, fines, penalties or the
          like. Provided, however, that Owner shall not be responsible to
          Manager for any such expenses in the event Manager is finally adjudged
          to have violated any such law and acted personally or beyond the scope
          of the agency rela-tionship intended herein, and not in a
          representative or agency capacity.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(E)</span> To inform Manager before conveying
          or leasing the Property.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(F)</span> Upon termination of this Agreement,
          to assume obligations of all contracts that Manager entered into on
          Owner’s behalf.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(G)</span> To pay all amounts billed by
          Manager. Owner authorizes Manager to reimburse itself out of rents
          collected for any outstanding invoices.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(H)</span> To indemnify and hold harmless
          Manager and Manager’s officers, directors, agents and employees from
          all claims, demands, causes of action costs and expenses, including
          reasonable attorneys’ fees at all levels arising from liability to any
          person, personal injuries, property damages and interference with
          tenancies, accountants’ fees to the extent based on (i) Owner’s
          misstatement, negligence, action, inaction or failure to perform the
          obligations of this contract or any lease or agreement with a vendor;{" "}
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(ii)</span> the existence of undisclosed
          material facts about the Property; (ii) Manager’s performance, at
          Owner’s request, of any task beyond the scope of services regulated by
          Chapter 475, F.S., as amended, including Manager’s referral,
          recommendation of any vendor; or (4) services or products provided and
          expenses incurred by any vendor. This subparagraph will survive
          Manager’s performance and the transfer or title.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(iii)</span> To authorize or make repairs
          necessary to transfer a reasonable safe dwelling unit to the tenant.
        </h3>
        <h3 className="clauseContent">
          (J) To exercise reasonable care to repair dangerous defective
          conditions upon notice of their existence.
        </h3>
        <h3 className="clauseContent">
          (K) To update Manager of changes to any governing bodies’ mailing
          address and/or owner of record. Owner hereby verifies that the mailing
          address, owner of record, and any financial obligation are current.
          All notices and fines imposed by governing bodies are deliver via U.S.
          Mail to the mailing address listed in their records. Manager is not be
          responsible for any monetary fines imposed by any governing bodies.
        </h3>
        <h3 className="clauseContent">
          (L) Homeowner’s association online access must be provided in Section
          12 (below). In order for Manager to access current ledger information,
          notices or others, Owner must include all logins and passwords of
          homeowner’s association management website.
        </h3>
        <h3 className="clauseContent">
          (M) If Owner has retained a service provider or warranty company,
          Owner must fill in the contact information for said provider in
          Section 12 (below). Additionally, Owner must name Manager as an
          authorized representative.
        </h3>
        <h3 className="clauseContent">
          (N) Owner is not to communicate directly with tenants. If the Owner
          receives direct communication from tenants, Owner must relay
          information to Manager.
        </h3>
        <h3 className="clauseContent">
          (O) Upon vacancy of the property Owner must maintain and have active
          electric and water utilities services. When requested, and at
          Manager’s discretion, Manager can connect services for property
          utilizing Manager’s accounts and with Manager’s credit wordiness a
          processing fee will be added.
        </h3>
        <h3 className="clauseContent">
          (P) Provide Tax ID number. U.S. Tax Law requires Property managers to
          issue a 1099-MISC tax form for any property Owner who receives more
          than $600 related to their rental business.
        </h3>
      </div>
      <div className="clauseSixAndSeven">
        <h3 className="clauseContent">
          <span className="fBold">6. COMPENSATION:</span>
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">Owner</span> agrees to compensate{" "}
          <span className="fBold">Manager</span> and Other Brokers:
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(A) Owner</span> will compensate{" "}
          <span className="fBold">Manager</span> 6.0% of the total purchase
          price no later than the date of closing specified in the sales
          contract. However, closing is not a prerequisite for{" "}
          <span className="fBold">Manager’s</span> fee being earned. If an
          option is exercised, Owner will pay{" "}
          <span className="fBold">Manager</span> the same fee (as stated above)
          for procuring a buyer who is ready, willing, and able to purchase the
          Property or any interest in the Property on the terms of this
          Agreement or on any other terms acceptable to{" "}
          <span className="fBold">Owner</span>.{" "}
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(B)</span> Notice to{" "}
          <span className="fBold">Owner</span>: The buyer’s broker, even if
          compensated by <span className="fBold">Owner</span> or{" "}
          <span className="fBold">Manager</span>, may represent the interests of
          the buyer. <span className="fBold">Manager’s</span> office policy is
          to cooperate with all other brokers except when not in{" "}
          <span className="fBold">Owner’s</span> best interest and to offer
          compensation in the amount of 3.0% of the purchase price to a single
          agent, transaction broker or a broker who has no brokerage
          relationship with the buyer.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(C)</span> New leases and renewals: one month
          of lease value as a leasing fee, if{" "}
          <span className="fBold">Owner</span> enters into any new lease, on the
          date <span className="fBold">Owner</span> enters into a lease or
          agreement to lease, whichever is earlier. Renewal is 1/2 of a month of
          lease value of the original lease with a tenant placed in the Property
          by or through <span className="fBold">Manager</span>.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(D)</span> For managing tenant relations and
          the property: 10% of gross lease value to be paid on a monthly basis.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(E) Manager’s</span> fee is due in the
          following circumstances: (1) If any interest in the Property is
          transferred, whether by sale, lease, exchange, governmental action,
          bankruptcy, or any other means of transfer, with the assistance of any
          real estate licensee. (2) If <span className="fBold">Owner</span>{" "}
          refuses or fails to sign an offer at the price and terms stated in
          this Agreement, defaults on an executed sales contract, or agrees with
          a buyer to cancel an executed sales contract. (3) If, within 180 days
          after Termination Date (“Protection Period”),{" "}
          <span className="fBold">Owner</span> transfers or contracts to
          transfer the Property or any interest in the Property to any prospects
          with whom <span className="fBold">Manager</span> or any other real
          estate licensee communicated regarding the Property before Termination
          Date. However, no fee will be due to{" "}
          <span className="fBold">Manager</span> if the Property is relisted
          after Termination Date and sold through another broker.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">(K)</span> Retained Deposits: As consideration
          for Broker’s services, Broker is entitled to receive 50% of all
          deposits that <span className="fBold">Owner</span> retains as
          liquidated damages for a buyer’s default in a transaction, not to
          exceed the Paragraph 6(a) fee.{" "}
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">7. SERVICES EXCLUDED:</span> This Agreement
          does not include providing tax, accounting, or legal advice. Owner
          shall be solely responsible for assuring compliance of the premises
          with applicable laws and Owner’s compliance with tax reporting
          requirements. This Agreement does not include on-site management
          services, refinancing, preparation of the premises for refinancing,
          modernization, fire or major damage restoration, rehabilitation,
          representation or appearance before public agencies or in legal
          proceedings, advising on proposed new construction, debt collection,
          consultation, attendance at association meetings or public hearings,
          except to the extent expressly set forth above or otherwise agreed in
          writing. Manager shall in any event be entitled to payment by Owner of
          Special Services Compensation for any such service if provided at the
          rate of $88.00 per hour.
        </h3>
      </div>
      <div className="clauseEightToEleven">
        <h3 className="clauseContent">
          <span className="fBold">8. DISPUTE RESOLUTIONS:</span> This Agreement
          will be construed under Florida law. All disputes between{" "}
          <span className="fBold">Manager</span> and{" "}
          <span className="fBold">Owner</span> based on this Agreement or its
          breach will be mediated under the rules of the American Arbitration
          Association or another mediator agreed upon by the parties. Mediation
          is a process in which parties attempt to resolve a dispute by
          submitting it to an impartial mediator who facilitates the resolution
          of the dispute but who is not empowered to impose a settlement on the
          parties. The parties will equally divide the mediation fee, if any. In
          any litigation based on this Agreement, the prevailing party will be
          entitled to recover reasonable attorneys’ fees and costs at all
          levels, unless the parties agree that disputes will be settled by
          arbitration as follows: Arbitration -{" "}
          <span className="fBold">Owner</span> and{" "}
          <span className="fBold">Manager</span> agree that disputes not
          resolved by mediation will be settled by neutral binding arbitration
          in the county in which the Property is located in accordance with the
          rules of the American Arbitration Association or other arbitrator
          agreed upon by the parties. The arbitrator may not alter the Contract
          terms. Each party to any arbitration or litigation (including appeals
          and interpleaders) will pay its own fees, costs and expenses,
          including attorneys’ fees at all levels, and will equally split the
          arbitra-tors’ fees and administrative fees of arbitration.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">9. ATTORNEYS’ FEES:</span> In any action
          between <span className="fBold">Owner</span> and a tenant in which{" "}
          <span className="fBold">Manager</span> is made a party because of
          acting as an escrow agent under this Agreement, or if{" "}
          <span className="fBold">Manager</span> interpleads escrowed funds,
          <span className="fBold">Manager</span> will recover reasonable
          attorneys’ fees and costs, to be paid out of the escrowed funds and
          charged and awarded as court costs in favor of the prevailing party.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">10. MISCELLANEOUS:</span> This Agreement is
          binding on <span className="fBold">Manager’s</span> and{" "}
          <span className="fbold">Owner’s</span> heirs, personal
          representatives, administrators, successors and assigns. Signatures,
          initials, documents referenced in the Agreement, counterparts and
          modifications communicated electronically or on paper will be
          acceptable for all purposes and will be binding. Major work, were
          defined or implied, are services of a value of $1,000.00 or higher.
        </h3>
        <h3 className="clauseContent">
          <span className="fBold">11. ADDITIONAL CLAUSES:</span> Either party
          must notify the other, in writing, upon the termination date of this
          agreement the desire to terminate. If no notice is received, the terms
          of this agreement should continue and this agreement will auto-renew
          for an equal term as the term stated in this agreement. In addition to
          conventional written communication,{" "}
          <span className="fBold">Manager</span> shall be entitled to rely on
          approvals or instructions from Owner which are received by or
          confirmed in email transmissions, text messages or other electronic
          forms of communication established by the custom and practice of the
          parties.
        </h3>
      </div>
    </section>
  );
}
