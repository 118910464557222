import { useEffect, useState } from "react";
import "./BeachCleanUp.css";

export default function BeachCleanUp() {
  const [Image, setImage] = useState("image-a");
  const [timeCarrousel, setTimeCarrousel] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (Image === "image-a") {
        setImage("image-b");
        setTimeCarrousel(2);
      } else if (Image === "image-b") {
        setImage("image-c");
        setTimeCarrousel(3);
      } else {
        setImage("image-a");
        setTimeCarrousel(1);
      }
    }, 22000);
  }, [timeCarrousel, Image]);

  return (
    <div className="beach-main-container" id="beach-clean">
      <div className="beach-container">
        <div className="beach-info">
          <h1 className="beach-title">BEACH CLEANUP</h1>
          <p className="beach-text">
            <span className="beach-text-bold">A Paradise with a Problem:</span>{" "}
            <a
              href="https://www.google.com/maps/place/Playa+de+la+Pen%C3%ADnsula+de+Bar%C3%BA/@10.1694849,-75.6580888,2a,75y,32.86h,98.11t,0r/data=!3m7!1e1!3m5!1scD8xsfKSpxn9tCvMqCzvOA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DcD8xsfKSpxn9tCvMqCzvOA%26cb_client%3Dsearch.gws-prod.gps%26w%3D211%26h%3D120%26yaw%3D0%26pitch%3D-20%26thumbfov%3D100!7i13312!8i6656!4m15!1m8!3m7!1s0x8e5887aa3c1b1d33:0x14ef5ac3fdda0952!2sPlaya+de+la+Pen%C3%ADnsula+de+Bar%C3%BA!8m2!3d10.1697267!4d-75.6573203!10e5!16s%2Fg%2F121dc64t!3m5!1s0x8e5887aa3c1b1d33:0x14ef5ac3fdda0952!8m2!3d10.1697267!4d-75.6573203!16s%2Fg%2F121dc64t?coh=205409&entry=ttu"
              target="_blank"
              rel="noreferrer"
              className="beach-text-link"
            >
              Baru's, Colombia
            </a>{" "}
            stunning beaches, a magnet for tourists, suffer from daily trash
            left behind. Blu World takes action!
          </p>
          <p className="beach-text">
            <span className="beach-text-bold">
              Our Mission is to lead by example:
            </span>{" "}
            We recruit volunteers who might not otherwise get to experience a
            beach, offering them a chance to witness the beauty they'll help
            protect.
          </p>
          <p className="beach-text">
            <span className="beach-text-bold">Funding Challenges:</span> Travel,
            lodging, and training for volunteers. Securing permits and managing
            waste disposal.
          </p>
          <p className="beach-text">
            <span className="beach-text-bold">Long-Term Vision:</span>{" "}
            Installing permanent trash and recycling systems collaborate with
            locals for ongoing beach care.
          </p>
        </div>
        <div className="beach-carrousel">
          <div className="beach-image-container" id={Image}></div>
          <div className="beach-carrousel-btn-container">
            <button
              className="beach-carrousel-btn"
              onClick={() => setImage("image-a")}
            ></button>
            <button
              className="beach-carrousel-btn"
              onClick={() => setImage("image-b")}
            ></button>
            <button
              className="beach-carrousel-btn"
              onClick={() => setImage("image-c")}
            ></button>
          </div>
        </div>
      </div>
      <button className="beach-stayupdate">Stay Updated</button>
      <p className="beach-footer">
        Subscribe for clean-up progress and future initiatives.
      </p>
    </div>
  );
}
