import React from "react";
import "./HeroBioPage.css";
const BluWhiteLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Logos%2FBluWhite.svg?alt=media&token=3cc5840f-1ea3-4b12-92c1-61303a0b940e";
const DownArrow =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/Icon%2FbioPageDownArrow.svg?alt=media&token=cbb3be6f-8c6a-4258-8ca0-ea6666e85a6c";

const HeroBioPage = () => {
  return (
    <div className="overlay-container">
      <section className="bioPageHero__section hero__overlayVideo">
        <div className="bioPageHero__logo">
          <img
            src={BluWhiteLogo}
            alt="Blu Realty & Investments"
            draggable="false"
          />
        </div>
        <h1 className="bioPageHero__title">
          Life is Sweet when Stress is Minimal!
        </h1>
        <div className="bioPageHero__downArrow">
          <img draggable="false" src={DownArrow} alt="Down Arrow" />
        </div>
      </section>
    </div>
  );
};

export default HeroBioPage;
