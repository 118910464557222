import { React, useState } from "react";

const SectionTwelve = ({ onDataChange }) => {
  const [part1Data, setPart1Data] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart1Data({
      ...part1Data,
      [name]: value,
    });
    onDataChange({
      ...part1Data,
      [name]: value,
    });
  };

  return (
    <section className="sectionTwelve__Section">
      <h2 className="section__Title">
        <span>Description of Property</span>
      </h2>

      <div className="sectionTwelve__Inputs propertyAddressInput">
        <label htmlFor="Street_Address2" className="sectionTwelve__Label">
          (A) Street Address:
        </label>
        <input
          type="text"
          className="sectionTwelve__Input"
          id="Street_Address2"
          name="Street_Address2"
          onChange={handleChange}
        />
      </div>

      <div className="propertyAddressInputs">
        <div className="sectionTwelve__Inputs">
          <label htmlFor="City1" className="sectionTwelve__Label">
            City:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="City1"
            name="City1"
            onChange={handleChange}
          />
        </div>

        <div className="sectionTwelve__Inputs">
          <label htmlFor="State1" className="sectionTwelve__Label">
            State:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="State1"
            name="State1"
            onChange={handleChange}
          />
        </div>

        <div className="sectionTwelve__Inputs">
          <label htmlFor="Zipcode" className="sectionTwelve__Label">
            Zipcode:
          </label>
          <input
            type="text"
            className="sectionTwelve__Input"
            id="Zipcode"
            name="Zipcode"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="sectionTwelve__Inputs marginTopOne legalDescription">
        <label htmlFor="MA_Legal_Description" className="sectionTwelve__Label">
          (B) Legal Description:
        </label>
        <input
          type="text"
          className="sectionTwelve__Input"
          id="MA_Legal_Description"
          name="MA_Legal_Description"
          onChange={handleChange}
        />
      </div>

      <div className="sectionTwelve__PersonalProperty marginTopOne">
        <label className="sectionTwelve__Label">(C) Personal Property:</label>
      </div>

      <div className="gridDropdowns">
        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Uno" }}
        >
          <label className="customSelect">
            <select
              name="Refrigerator"
              id="Refrigerator"
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label
            htmlFor="Refrigerator"
            className="sectionTwelve__DropdownLabel"
          >
            Refrigerator
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Dos" }}
        >
          <label className="customSelect">
            <select name="Stove" id="Stove" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="Stove" className="sectionTwelve__DropdownLabel">
            Stove
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Tres" }}
        >
          <label className="customSelect">
            <select name="Microwave" id="Microwave" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="Microwave" className="sectionTwelve__DropdownLabel">
            Microwave
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Cuatro" }}
        >
          <label className="customSelect">
            <select name="Dishwasher" id="Dishwasher" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="Dishwasher" className="sectionTwelve__DropdownLabel">
            Dishwasher
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Cinco" }}
        >
          <label className="customSelect">
            <select name="Dryer" id="Dryer" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="Dryer" className="sectionTwelve__DropdownLabel">
            Dryer
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Seis" }}
        >
          <label className="customSelect">
            <select name="Washer" id="Washer" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="Washer" className="sectionTwelve__DropdownLabel">
            Washer
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Siete" }}
        >
          <label className="customSelect">
            <select
              name="Garbage_Disposal"
              id="Garbage_Disposal"
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label
            htmlFor="Garbage_Disposal"
            className="sectionTwelve__DropdownLabel"
          >
            Garbage Disposal
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer"
          style={{ gridArea: "Ocho" }}
        >
          <label className="customSelect">
            <select
              name="Garage_Opener"
              id="Garage_Opener"
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label
            htmlFor="Garage_Opener"
            className="sectionTwelve__DropdownLabel"
          >
            Garage Opener
          </label>
        </div>
      </div>

      <h2 className="propertyKeys__Title">
        <span>(D) Owner </span>provides the following keys to the property:
      </h2>

      <div className="dropdownRow">
        <div
          className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
          style={{ gridArea: "KeysOne" }}
        >
          <label className="customSelect">
            <select name="MA_KeyUnit" id="MA_KeyUnit" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="MA_KeyUnit" className="sectionTwelve__DropdownLabel">
            Unit
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
          style={{ gridArea: "KeysTwo" }}
        >
          <label className="customSelect">
            <select
              name="MA_KeyBuildingAccess"
              id="MA_KeyBuildingAccess"
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label
            htmlFor="MA_KeyBuildingAccess"
            className="sectionTwelve__DropdownLabel"
          >
            Building Access
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
          style={{ gridArea: "KeysThree" }}
        >
          <label className="customSelect">
            <select
              name="MA_KeyMailbox"
              id="MA_KeyMailbox"
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label
            htmlFor="MA_KeyMailbox"
            className="sectionTwelve__DropdownLabel"
          >
            Mailbox
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
          style={{ gridArea: "KeysFour" }}
        >
          <label className="customSelect">
            <select name="MA_KeyPool" id="MA_KeyPool" onChange={handleChange}>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label htmlFor="MA_KeyPool" className="sectionTwelve__DropdownLabel">
            Pool
          </label>
        </div>

        <div
          className="sectionTwelve__DropdownContainer sectionTwelve__DropdownContainer--W"
          style={{ gridArea: "KeysFive" }}
        >
          <label className="customSelect">
            <select
              name="MA_KeyGarageDoorOpener"
              id="MA_KeyGarageDoorOpener"
              onChange={handleChange}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </label>
          <label
            htmlFor="MA_KeyGarageDoorOpener"
            className="sectionTwelve__DropdownLabel"
          >
            Garage Door Opener
          </label>
        </div>
      </div>

      <div className="propertyOccupancy">
        <h2 className="propertyType__Title">(E) Type of property:</h2>
        <div className="checkboxContainer">
          <label className="checkbox-label">
            Condominium
            <input
              type="radio"
              value="Condominium"
              name="TypeOfProperty"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label className="checkbox-label">
            Homeowners
            <input
              type="radio"
              value="Homeowners"
              name="TypeOfProperty"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label className="checkbox-label">
            Free Standing
            <input
              type="radio"
              value="Free Standing"
              name="TypeOfProperty"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>

      <div className="propertyOccupancy">
        <h2 className="propertyOccupancy__Title">(F) Occupancy:</h2>
        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="Occupancy_isLeased">
            Property is Leased
            <input
              type="radio"
              id="Occupancy_isLeased"
              name="MA_Property_Occupancy"
              value="Is Leased"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="Occupancy_Occupied">
            Owner Occupied
            <input
              type="radio"
              id="Occupancy_Occupied"
              name="MA_Property_Occupancy"
              value="Owner Occupied"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="checkboxContainer">
          <label className="checkbox-label" htmlFor="Occupancy_Vacant">
            Vacant
            <input
              type="radio"
              id="Occupancy_Vacant"
              name="MA_Property_Occupancy"
              value="Vacant"
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>

      <h3 className="copyLatestLeaseAgreements">
        Please provide a copy of the latest lease agreement(s) and tenant(s)
        contact information.
      </h3>
      <div className="copyLatestLeaseAgreements__Inputs">
        <input
          type="text"
          placeholder="Tenant Name"
          className="tenantInfo__Input"
          name="Tenant_Name"
          onChange={handleChange}
        />
        <input
          type="tel"
          placeholder="Phone"
          className="tenantInfo__Input"
          name="Tenant_Phone"
          onChange={handleChange}
        />
        <input
          type="email"
          placeholder="Email"
          className="tenantInfo__Input"
          name="Tenant_Email"
          onChange={handleChange}
        />
      </div>

      <div className="uploadFileContainer">
        <svg
          fill="#ffffff"
          height="24px"
          width="24px"
          viewBox="0 0 330.591 330.591"
        >
          <path
            d="M52.575,320.395c-0.693,0-1.391-0.015-2.09-0.043c-12.979-0.54-25.361-6.071-34.865-15.576
              c-9.504-9.504-15.035-21.886-15.576-34.864c-0.549-13.213,4.115-25.456,13.133-34.475L221.581,27.033
              c11.523-11.523,27.197-17.483,44.096-16.78c16.676,0.693,32.594,7.81,44.822,20.037c12.228,12.229,19.346,28.147,20.037,44.823
              c0.703,16.911-5.256,32.571-16.781,44.096L156.711,276.255c-2.928,2.927-7.676,2.928-10.607,0
              c-2.928-2.93-2.928-7.678,0-10.608l157.045-157.047c8.523-8.522,12.928-20.194,12.4-32.865
              c-0.537-12.906-6.098-25.279-15.658-34.84c-9.559-9.56-21.932-15.119-34.838-15.656c-12.67-0.533-24.344,3.876-32.865,12.399
              L23.784,246.044c-12.596,12.594-11.498,34.184,2.443,48.125c6.836,6.837,15.672,10.813,24.881,11.195
              c8.975,0.349,17.229-2.734,23.244-8.752l169.441-169.439c7.422-7.422,6.691-20.229-1.629-28.549
              c-4.113-4.114-9.414-6.505-14.924-6.733c-5.289-0.212-10.115,1.595-13.625,5.106L95.536,215.08
              c-2.93,2.927-7.678,2.928-10.607,0c-2.93-2.93-2.93-7.678,0-10.607L203.008,86.39c6.512-6.512,15.322-9.9,24.855-9.486
              c9.281,0.385,18.127,4.332,24.906,11.114c14.17,14.167,14.9,36.49,1.631,49.762L84.959,307.22
              C76.418,315.76,64.985,320.395,52.575,320.395z"
          />
        </svg>
        <input type="file" placeholder="none" className="uploadFile" />
        <span>Upload File</span>
      </div>
    </section>
  );
};

export default SectionTwelve;
