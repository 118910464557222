import React, { useState, useEffect } from "react";
import "./StarContact.css"; // Asegúrate de tener un archivo de CSS para las clases utilizadas

const StarContact = () => {
  const [showInputs, setShowInputs] = useState(false);

  const handleCheckboxChange = () => {
    // Cambia el estado a 'true' si algún checkbox está marcado
    setShowInputs(true);
  };

  useEffect(() => {
    // Función que maneja la visibilidad de los inputs y botón
    const toggleStarFormInputs = () => {
      const checkboxes = document.querySelectorAll(".starForm__CheckboxInput");
      const starFormInputs = document.querySelector(".starFormInputs");
      const starFormButton = document.querySelector(".starForm_Bttn");
      const starContact = document.querySelector(".starContact");

      // Revisa si al menos un checkbox está seleccionado
      const isChecked = Array.from(checkboxes).some(
        (checkbox) => checkbox.checked
      );

      if (isChecked) {
        // Quitar displayNone y forzar un reflujo
        starFormInputs.classList.remove("displayNone");
        starFormButton.classList.remove("displayNone");

        // Agregar la clase 'visible' para preparar la transición
        starFormInputs.classList.add("visible");
        starFormButton.classList.add("visible");

        // Cambiar la altura de .starContact
        starContact.classList.add("expanded");

        // Forzar un reflujo antes de agregar la clase que muestra los elementos
        void starFormInputs.offsetWidth; // Fuerza un reflow
        void starFormButton.offsetWidth; // Fuerza un reflow

        // Ahora, aplica la clase que activa la transición de opacidad
        starFormInputs.classList.add("showInputs");
        starFormButton.classList.add("showButton");
      } else {
        // Eliminar la clase que activa la transición
        starFormInputs.classList.remove("showInputs");
        starFormButton.classList.remove("showButton");

        // Volver la altura de .starContact a su estado original
        starContact.classList.remove("expanded");

        // Después de la transición, quitar la clase 'visible' y aplicar 'displayNone'
        setTimeout(() => {
          starFormInputs.classList.remove("visible");
          starFormButton.classList.remove("visible");

          starFormInputs.classList.add("displayNone");
          starFormButton.classList.add("displayNone");
        }, 500); // Tiempo de la transición
      }
    };

    // Añadir event listeners a todos los checkboxes
    const checkboxes = document.querySelectorAll(".starForm__CheckboxInput");
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", toggleStarFormInputs);
    });

    // Comprobar el estado inicial en caso de que algún checkbox ya esté seleccionado
    toggleStarFormInputs();

    // Limpiar los event listeners al desmontar el componente
    return () => {
      checkboxes.forEach((checkbox) => {
        checkbox.removeEventListener("change", toggleStarFormInputs);
      });
    };
  }, []); // El efecto se ejecuta una vez al montar el componente

  return (
    <section className="starContact">
      <form action="#" className="starForm" method="post">
        <div className="starForm__Checkboxes">
          <p className="starForm__text">Are you looking to:</p>
          <div className="starForm__CheckboxContainer">
            <div className="starForm__Checkbox">
              <p className="starForm__CheckboxText">Buy</p>
              <input
                type="checkbox"
                id="roundedCheckboxB"
                className="starForm__CheckboxInput"
                name="Buy"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="roundedCheckboxB"></label>
            </div>
            <div className="starForm__Checkbox">
              <p className="starForm__CheckboxText">Sell</p>
              <input
                type="checkbox"
                id="roundedCheckboxS"
                className="starForm__CheckboxInput"
                name="Sell"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="roundedCheckboxS"></label>
            </div>
            <div className="starForm__Checkbox">
              <p className="starForm__CheckboxText">Management</p>
              <input
                type="checkbox"
                id="roundedCheckboxM"
                className="starForm__CheckboxInput"
                name="Management"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="roundedCheckboxM"></label>
            </div>
          </div>
        </div>
        <div className={`starFormInputs ${showInputs ? "" : "displayNone"}`}>
          <input type="text" placeholder="Name" id="nameInput" />
          <input type="email" placeholder="E-mail" id="emailInput" />
          <input
            type="tel"
            placeholder="Phone"
            className="starForm_phoneInput"
            id="phoneInput"
          />
        </div>
        <input
          type="submit"
          value="Submit"
          className={`starForm_Bttn ${showInputs ? "" : "displayNone"}`}
        />
      </form>
    </section>
  );
};

export default StarContact;
