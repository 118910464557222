import React, { useState, useEffect } from "react";

const ContactInformation = ({ onDataChange }) => {
  const [showSecondEmail, setShowSecondEmail] = useState(false);
  const [showSecondTelephone, setShowSecondTelephone] = useState(false);
  const [part3Data, setPart3Data] = useState({});
  const [countryCodes, setCountryCodes] = useState([]);

  // Cargar los datos de CountryCodes.json cuando el componente se monte
  useEffect(() => {
    // Simulamos la carga desde un archivo JSON
    fetch("./assets/CountryCodes.json")
      .then((response) => response.json())
      .then((data) => setCountryCodes(data))
      .catch((error) =>
        console.error("Error cargando CountryCodes.json:", error)
      );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPart3Data({
      ...part3Data,
      [name]: value,
    });
    onDataChange({
      ...part3Data,
      [name]: value,
    });
  };

  return (
    <section className="contactInformation__Section">
      <h2 className="section__Title">
        <span>Contact Information</span>
      </h2>

      <input
        type="text"
        placeholder="Name of person we are to update and contact when needed"
        className="contactInformation__Input contactInformation__Input--Name"
        name="Name_Contact"
        id="contactInformation_Name"
        onChange={handleChange}
      />

      <div className="dFlex contactInformation__Inputs">
        <div className="dFColumn">
          <label
            htmlFor="Email_Contact"
            className="sectionTwelve__Label mTwoRem"
          >
            Email:
          </label>
          <input
            type="email"
            className="contactInformation__Input"
            name="Email_Contact"
            id="contactInformation_Email"
            onChange={handleChange}
          />
          <span
            id="contact_addEmail"
            className="addInfo"
            onClick={() => setShowSecondEmail(!showSecondEmail)}
          >
            + Add Second Email
          </span>
          {showSecondEmail && (
            <input
              type="email"
              className="contactInformation__Input"
              name="Secondary_Email_Contact"
              id="contactInformation_SecondEmail"
              onChange={handleChange}
            />
          )}
        </div>

        <div className="dFColumn">
          <label
            htmlFor="Mobile_Contact"
            className="sectionTwelve__Label mTwoRem"
          >
            Mobile:
          </label>
          <div className="dFlex">
            <div
              className="sectionTwelve__DropdownContainer"
              style={{ gridArea: "Uno" }}
            >
              <label className="customSelect">
                <select
                  name="countryCode"
                  id="countryCode"
                  onChange={handleChange}
                >
                  <option value="">+1</option>
                  {countryCodes.map((country) => (
                    <option key={country.code} value={country.dial_code}>
                      {country.dial_code}
                    </option>
                  ))}
                </select>
              </label>
              <label
                htmlFor="PersonalProperty_Refrigerator"
                className="sectionTwelve__DropdownLabel"
              ></label>
            </div>
            <input
              type="tel"
              className="contactInformation__Input"
              name="Mobile_Contact"
              id="contactInformation_Mobile"
              onChange={handleChange}
            />
          </div>
          <span
            id="contact_addTelephone"
            className="addInfo"
            onClick={() => setShowSecondTelephone(!showSecondTelephone)}
          >
            + Add Second Telephone
          </span>
          {showSecondTelephone && (
            <input
              type="tel"
              className="contactInformation__Input"
              name="Secondary_Mobile_Contact"
              id="contactInformation_SecondMobile"
              onChange={handleChange}
            />
          )}
        </div>
      </div>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label
          htmlFor="contactInformation_Address"
          className="sectionTwelve__Label weightBold"
        >
          Address:
        </label>
        <input
          type="text"
          name="contactInformation_Address"
          className="sectionTwelve__Input"
          id="contactInformation_Address"
          onChange={handleChange}
        />
      </div>

      <div className="sectionTwelve__Inputs sectionTwelve__Inputs--Address">
        <label htmlFor="City_Contact" className="sectionTwelve__Label">
          City:
        </label>
        <input
          type="text"
          name="City_Contact"
          className="sectionTwelve__Input"
          id="City_Contact"
          onChange={handleChange}
        />
        <label
          htmlFor="State_Contact"
          className="sectionTwelve__Label marginLeft"
        >
          State:
        </label>
        <input
          type="text"
          name="State_Contact"
          className="sectionTwelve__Input"
          id="contactInformation_State"
          onChange={handleChange}
        />
        <label
          htmlFor="Zip_Code_Contact"
          className="sectionTwelve__Label marginLeft"
        >
          ZipCode:
        </label>
        <input
          type="text"
          name="Zip_Code_Contact"
          className="sectionTwelve__Input"
          id="contactInformation_ZipCode"
          onChange={handleChange}
        />
      </div>
      <input
        type="text"
        placeholder="Note"
        className="noteInput"
        name="Note_Contact"
        id="contactInformation_Note"
      />
    </section>
  );
};

export default ContactInformation;
