const PrimarySection = ({ isActive }) => (
  <div
    className={`bio-content__primary ${isActive ? "active" : "hidden"}`}
    style={{
      opacity: isActive ? 1 : 0,
      transition: "opacity 0.5s ease-in-out",
    }}
  >
    <div className="bio-content__primary__container">
      <h3 className="bio-agent-name">Jean</h3>
      <h3 className="bio-agent-last-name">Parra</h3>
      <h3 className="bio-agent-subtitle">Licensed Real Estate Broker.</h3>
      <div className="bio-agent-primaryContact">
        <div className="bio-agent-icon">
          <div className="bio-agent-icon__content">
            <div className="bio-agent-iconImg bio-agent-iconImg--Phone"></div>
            <p className="bio-agent-iconTitle">Phone:</p>
          </div>
          <div className="bio-agent-icon__phone">
            <p className="bio-agent-phone">305.800.BLUR</p>
            <p className="bio-agent-phone-digits">(2587)</p>
          </div>
        </div>
        <div className="bio-agent-icon">
          <div className="bio-agent-icon__content">
            <div className="bio-agent-iconImg bio-agent-iconImg--Email"></div>
            <p className="bio-agent-iconTitle">E-Mail:</p>
          </div>
          <p className="bio-agent-email">jp@blurealty.ai</p>
        </div>
      </div>
    </div>
  </div>
);

export default PrimarySection;
