import style from "./ObserverHead.module.css";
const ObserverLogo =
  "https://firebasestorage.googleapis.com/v0/b/blurealtywebapp.appspot.com/o/BluWorld%2FImages%2FObserverLogo.svg?alt=media&token=7d3d74f8-b424-4d67-aeb9-a6aac6c3d3de";

function ObserverHead() {
  return (
    <div className={style.logoContainer}>
      <img
        src={ObserverLogo}
        alt="observer logo"
        className={style.logoObserver}
      />
    </div>
  );
}

export default ObserverHead;
